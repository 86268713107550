import { VerseBody2, VerseBox, VerseSubtitle3 } from "src/verse/src";
import { StyledRangeInput } from "src/components/FormElements/StyledRangeInput";
import { useVirtualBgContext } from "src/context/VirtualBgContext";
export function TextYInput() {
  const { textY, textYChange } = useVirtualBgContext();
  return (
    <VerseBox>
      <VerseBody2 mb={1}>Y offset</VerseBody2>
      <StyledRangeInput
        value={textY}
        onChange={(evt) => textYChange?.(Number(evt.target.value))}
        min="-50"
        max="80"
      />
    </VerseBox>
  );
}
