import { VerseBody2, VerseBox, VerseSubtitle3 } from "src/verse/src";
import { StyledRangeInput } from "src/components/FormElements/StyledRangeInput";
import { useVirtualBgContext } from "src/context/VirtualBgContext";
export function GradientDegreeInput() {
  const { gradientAngle, gradientAngleChange } = useVirtualBgContext();
  return (
    <VerseBox>
      <VerseBody2 mb={1.6}>Gradient angle</VerseBody2>
      <StyledRangeInput
        value={gradientAngle}
        onChange={(evt) => gradientAngleChange?.(Number(evt.target.value))}
        min="0"
        max="360"
      />
    </VerseBox>
  );
}
