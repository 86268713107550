type INSObject = Record<string, any>;

export function createNSObjects(jsonArr: INSObject[]): INSObject {
  const nsObjects = {};
  jsonArr.forEach(nsObj => {
    if (nsObj.namespace) {
      nsObjects[nsObj.namespace] = { ...nsObj };
    }
  });

  return nsObjects;
}
