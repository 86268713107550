import React from 'react';

import { useVerseTheme } from '../../..';
import { VerseTypographyFontWeightEnum } from '../consts';
import { VerseTypographyComponentProps, VerseTypographyVariantProps } from '../typings';
import { WrappedVerseTypography } from '../WrappedVerseTypography';

export const HelpText = (props: VerseTypographyComponentProps) => {
  const theme = useVerseTheme();
  const variantProps: VerseTypographyVariantProps = {
    fontSize: 13,
    lineHeight: 1.2,
    fontWeight: VerseTypographyFontWeightEnum.LIGHT,
    color: theme.colors.main.PrimaryDark50,
  };
  return <WrappedVerseTypography variantProps={variantProps} {...props} />;
};
