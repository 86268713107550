import { AspectRatioDiv } from "src/components/AspectRatioDiv";
import {
  VerseBox,
  VerseH2,
  VerseIcon,
  VerseIconIdEnum,
  VerseLabelText,
} from "src/verse/src";
import styled from "styled-components";

import { useVirtualBgContext } from "src/context/VirtualBgContext";
import { POSITION_PARAMS } from "src/consts/position";
import { BackgroundTypeEnum } from "src/consts/background";

const Gradient = styled.div<{
  hue: number;
  hue2: number;
  light: number;
  light2: number;
  saturation: number;
  saturation2: number;
  deg: number;
  backgroundType: BackgroundTypeEnum;
  backgroundImage?: string;
}>`
  ${({
    deg,
    hue,
    saturation,
    light,
    hue2,
    saturation2,
    light2,
    backgroundType,
    backgroundImage,
  }) => {
    switch (backgroundType) {
      case BackgroundTypeEnum.GRADIENT:
        return `background: linear-gradient(${deg}deg, hsl(${hue}, ${saturation}%, ${light}%), hsl(${hue2}, ${saturation2}%, ${light2}%));`;
      case BackgroundTypeEnum.SOLID:
        return `background: hsl(${hue}, ${saturation}%, ${light}%);`;
      case BackgroundTypeEnum.IMAGE:
        return `background: url("${backgroundImage}"); background-size: cover;`;
    }
  }}

  height: 100%;
  width: 100%;
  transition: background 0.2s ease-in-out;
`;

const CenteredAbsolute = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const ScaleContainer = styled.div<{
  scale: number;
  transformOrigin: string;
  rotation: number;
}>`
  transform: scale(${(props) => props.scale})
    ${(props) => (props.transformOrigin === "left" ? "translateX(-50%)" : "")}
    rotate(${(props) => props.rotation}deg);
  transform-origin: ${(props) => props.transformOrigin};
`;

function hexToHSL(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var r = parseInt(result?.[1] ?? "0", 16);
  var g = parseInt(result?.[2] ?? "0", 16);
  var b = parseInt(result?.[3] ?? "0", 16);

  (r /= 255), (g /= 255), (b /= 255);
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return { h, s, l };
}

export function VBGPreview() {
  const {
    gradientHex,
    gradientHex2,
    showHuman,
    aspectRatio,
    gradientAngle,
    textY,
    textPosition,
    logoPosition,
    textX,
    textDegree,
    textScale,
    textColor,
    text,
    logo,
    logoY,
    logoX,
    logoDegree,
    logoScale,
    backgroundType,
    backgroundImage,
  } = useVirtualBgContext();

  const { h: hue, s: saturation, l: light } = hexToHSL(gradientHex);
  const { h: hue2, s: saturation2, l: light2 } = hexToHSL(gradientHex2);

  const textPositionParam = POSITION_PARAMS[textPosition];
  const logoPositionParam = POSITION_PARAMS[logoPosition];

  return (
    <VerseBox borderRadius={24} width="100%" position="relative">
      {showHuman ? (
        <CenteredAbsolute>
          <VerseIcon iconId={VerseIconIdEnum.EMOJI_V2} size={200} />
          <VerseLabelText color="#000">This is you</VerseLabelText>
        </CenteredAbsolute>
      ) : null}
      <AspectRatioDiv borderRadius="24px" id="export" aspectRatio={aspectRatio}>
        <Gradient
          hue={hue}
          hue2={hue2}
          deg={gradientAngle}
          light={light}
          light2={light2}
          saturation={saturation}
          saturation2={saturation2}
          backgroundImage={backgroundImage}
          backgroundType={backgroundType}
        >
          <VerseBox
            position="absolute"
            top={`calc(${textY * 8}px + ${textPositionParam?.top})`}
            bottom={`calc(${textY * 8}px + ${textPositionParam?.bottom})`}
            right={`calc(${textX * 8}px + ${textPositionParam?.right})`}
            left={`calc(${textX * 8}px + ${textPositionParam?.left})`}
          >
            <ScaleContainer
              rotation={textDegree}
              scale={textScale}
              transformOrigin={textPositionParam.transformOrigin}
            >
              <VerseH2 color={textColor}>{text}</VerseH2>
            </ScaleContainer>
          </VerseBox>
          {logo ? (
            <VerseBox
              position="absolute"
              top={`calc(${logoY * 8}px + ${logoPositionParam?.top})`}
              bottom={`calc(${logoY * 8}px + ${logoPositionParam?.bottom})`}
              right={`calc(${logoX * 8}px + ${logoPositionParam?.right})`}
              left={`calc(${logoX * 8}px + ${logoPositionParam?.left})`}
            >
              <ScaleContainer
                rotation={logoDegree}
                scale={logoScale}
                transformOrigin={logoPositionParam.transformOrigin}
              >
                <img src={logo} />
              </ScaleContainer>
            </VerseBox>
          ) : null}
        </Gradient>
      </AspectRatioDiv>
    </VerseBox>
  );
}
