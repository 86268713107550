import styled from "styled-components";

import { useVirtualBgContext } from "src/context/VirtualBgContext";
import { HexSelectInput } from "src/components/HexSelectInput";
import { VerseBody2 } from "src/verse/src";
export function TextColorInput() {
  const { textColor, textColorChange } = useVirtualBgContext();
  return (
    <>
      <VerseBody2 mb={1}>Text color</VerseBody2>
      <HexSelectInput color={textColor} colorChange={textColorChange} />
    </>
  );
}
