import React, { useState } from "react";
import { components } from "react-select";
import styled from "styled-components";

import {
  VerseBody1,
  VerseBody2,
  VerseBody3,
  VerseIcon,
  VerseIconIdEnum,
} from "..";
import { useVerseTheme } from "../..";
import useHover from "../../utils/useHover";
import { VerseSelectSizeEnum } from "./consts";
import {
  VerseSelectOptionComponentProps,
  VerseSelectOptionContainerProps,
} from "./typings";

export function VerseSelectOption({
  data: { label, value, iconId, icon, Component, actionButton },
  selectProps,
  ...other
}: VerseSelectOptionComponentProps) {
  const isSelected =
    (selectProps?.value ?? selectProps?.value?.selectValue)?.value === value;
  const size = selectProps?.size;
  const testId = selectProps?.testId;
  const TextComponent = React.useMemo(() => {
    switch (size) {
      case VerseSelectSizeEnum.LARGE:
        return VerseBody1;
      case VerseSelectSizeEnum.MEDIUM:
        return VerseBody2;
      case VerseSelectSizeEnum.SMALL:
      default:
        return VerseBody3;
    }
  }, []);
  const [isActionButtonHovered, setIsHovered] = useState(false);
  const onHover = (hovered: boolean) => {
    setIsHovered(hovered);
  };
  const hoverEvent = useHover({ onHover });
  const theme = useVerseTheme();

  return (
    <div
      {...hoverEvent}
      data-testid={testId ? `${testId}-${value}` : undefined}
    >
      <LabelContainer
        {...(other as any)}
        size={size}
        focusedBgColor={theme.colors.other.Sand08}
        selectedColor={theme.colors.main.PrimaryDark100}
        defaultColor={theme.colors.main.PrimaryDark70}
      >
        <FillSpaceContainer>
          {Component ? (
            Component
          ) : (
            <>
              {iconId || icon ? (
                <>
                  {(() => {
                    const iconProps = icon
                      ? icon({ isSelected, value, label })
                      : { iconId };
                    return (
                      <VerseIcon
                        size={16}
                        stroke={
                          isSelected
                            ? theme.colors.main.PrimaryDark100
                            : theme.colors.main.PrimaryDark70
                        }
                        mr={0.75}
                        {...(iconProps ?? {})}
                        iconId={
                          iconId ??
                          iconProps?.iconId ??
                          VerseIconIdEnum.PLACEHOLDER
                        }
                      />
                    );
                  })()}
                </>
              ) : null}
              <TextWrapper>
                <TextComponent color="inherit" component="span">
                  {label}
                </TextComponent>
              </TextWrapper>
            </>
          )}
        </FillSpaceContainer>
        {actionButton?.title && isActionButtonHovered ? (
          <ActionButton
            onClick={(e) => {
              /** To not fire a select event */
              e.stopPropagation();
              actionButton.onClick();
            }}
            disabled={isSelected}
          >
            <VerseBody3>{actionButton.title}</VerseBody3>
          </ActionButton>
        ) : null}

        {isSelected ? (
          <SelectedIconContainer>
            <VerseIcon iconId={VerseIconIdEnum.CHECK_V1} size={12} />
          </SelectedIconContainer>
        ) : null}
      </LabelContainer>
    </div>
  );
}

const SelectedIconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;

  padding: 6px;
  background-color: ${({ theme }) => theme.colors.other.Sand08};
`;

interface LabelContainerProps extends VerseSelectOptionContainerProps {
  focusedBgColor: string;
  selectedColor: string;
  defaultColor: string;
}
const LabelContainer = styled(components.Option)<LabelContainerProps>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${({ defaultColor }) => defaultColor};
    ${({ size }) => {
      switch (size) {
        case VerseSelectSizeEnum.SMALL:
          return `
          min-height: 32px;
          padding: 0 8px;
          border-radius: 10px;
          `;
        case VerseSelectSizeEnum.MEDIUM:
          return `
          min-height: 40px;
          padding: 0 12px;
          border-radius: 12px;
          `;
        case VerseSelectSizeEnum.LARGE:
          return `
          min-height: 48px;
          padding: 0 16px;
          border-radius: 16px;
          `;
        default:
          return ``;
      }
    }}

    transition: ${({ theme }) => theme.animationSpeed}ms all linear;

    &.react-select__option--is-selected {
      background-color: unset;
      color: ${({ selectedColor }) => selectedColor};
    }
    &.react-select__option--is-focused {
      background-color: ${({ focusedBgColor }) => focusedBgColor};
    }
  }
`;
const FillSpaceContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;

const ActionButton = styled.button`
  width: 61px;
  height: 22px;
  border: none;
  border-radius: 8px;
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.main.PrimaryDark70};
  :hover {
    color: ${({ theme }) => theme.colors.main.PrimaryDark100};
    background: ${({ theme }) => theme.colors.other.Sand12};
  }

  ${({ disabled }) => {
    if (disabled) return `opacity: 0.2; pointer-events: none;`;
  }}
`;

const TextWrapper = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
