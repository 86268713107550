import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

import en_notes from "src/locales/en.json";
import { createNSObjects } from "./createNSObjects";
import { BUTTER_NOTES_NAMESPACES } from "src/consts/locale";

// English
// the translations
// (tip move them in a JSON file and import them)
const resources: Resource = {
  en: createNSObjects([en_notes]),
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    // list of all namespaces
    ns: [...BUTTER_NOTES_NAMESPACES],
    defaultNS: "common",

    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
