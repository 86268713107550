import React from "react";
import styled from "styled-components";

interface AspectRatioDivProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  aspectRatio?: number;
  forcePadding?: boolean;
  borderRadius?: string;
  id?: string;
}

export const AspectRatioDiv = ({
  children,
  aspectRatio = 16 / 9,
  forcePadding = false,
  borderRadius,
  id,
}: AspectRatioDivProps) => {
  /**
   * https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio
   * Browser compatibility for aspect-ratio:
   * Chrome 88
   * Edge 88
   * Safari 15 - not yet released to the general public
   */
  const supportsAspectRatioCSS = forcePadding
    ? false
    : CSS.supports("aspect-ratio: 16/9");
  return (
    <AspectRatioContainer
      id={id}
      aspectRatio={aspectRatio}
      supportsAspectRatioCSS={supportsAspectRatioCSS}
      borderRadius={borderRadius}
    >
      <TileContainer>{children}</TileContainer>
    </AspectRatioContainer>
  );
};

const AspectRatioContainer = styled.div<{
  aspectRatio: number;
  supportsAspectRatioCSS: boolean;
  maxWidth?: number;
  borderRadius?: string;
}>`
  display: inline-block;

  position: relative;
  width: 100%;
  max-height: 100%;
  overflow: hidden;

  ${({ supportsAspectRatioCSS, aspectRatio }) =>
    supportsAspectRatioCSS
      ? `aspect-ratio: ${aspectRatio};`
      : `
          height: 0;
          padding-top: ${(1 / aspectRatio) * 100}%;
          max-height: unset;`}

  ${({ borderRadius }) =>
    borderRadius ? `border-radius: ${borderRadius};` : ""}
    box-shadow: ${({ theme }) => theme.shadows.cardShadowNoBorder};
`;

const TileContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  left: 0;
`;
