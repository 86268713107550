import React, { useEffect } from "react";
import { VirtualBgContext } from "./VirtualBgContext";
import { PositionEnum } from "src/consts/position";
import { BackgroundTypeEnum } from "src/consts/background";
function hslToHex(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = (x) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

function hexToHSL(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var r = parseInt(result?.[1] ?? "0", 16);
  var g = parseInt(result?.[2] ?? "0", 16);
  var b = parseInt(result?.[3] ?? "0", 16);

  (r /= 255), (g /= 255), (b /= 255);
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return { h, s, l };
}
export function VirtualBgContextWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const [backgroundType, setBackgroundType] =
    React.useState<BackgroundTypeEnum>(BackgroundTypeEnum.GRADIENT);
  const [backgroundImage, setBackgroundImage] = React.useState<string>("");

  const [hue, setHue] = React.useState(60);
  const [hue2, setHue2] = React.useState(hue - 15);
  const [light, setLight] = React.useState(70);
  const [light2, setLight2] = React.useState(light);
  const [saturation, setSaturation] = React.useState(90);
  const [saturation2, setSaturation2] = React.useState(saturation - 5);
  const [deg, setDeg] = React.useState(180);

  const [textDeg, setTextDeg] = React.useState(0);
  const [text, setText] = React.useState("Custom Text Here");
  const [textScale, setTextScale] = React.useState(1);
  const [textXOffset, setTextXOffset] = React.useState(0);
  const [textYOffset, setTextYOffset] = React.useState(0);
  const [textPosition, setTextPosition] = React.useState(PositionEnum.TOP_LEFT);

  useEffect(() => {
    setTextYOffset(0);
    setTextXOffset(0);
  }, [textPosition]);

  const [textColor, setTextColor] = React.useState("#000");

  const [aspectRatioText, setAspectRatioText] = React.useState("4:3");

  const aspectRatio = React.useMemo(() => {
    const width = aspectRatioText.split(":")[0];
    const height = aspectRatioText.split(":")[1];
    return Number(width) / Number(height);
  }, [aspectRatioText]);

  const [logo, setLogo] = React.useState<string | null>(
    "https://files.butter.us/butter-logo-nobg-512.png"
  );
  const [logoScale, setLogoScale] = React.useState(0.4);
  const [logoXOffset, setLogoXOffset] = React.useState(0);
  const [logoYOffset, setLogoYOffset] = React.useState(0);
  const [logoDeg, setLogoDeg] = React.useState(0);

  const [logoPosition, setLogoPosition] = React.useState(
    PositionEnum.BOTTOM_RIGHT
  );

  useEffect(() => {
    setLogoXOffset(0);
    setLogoYOffset(0);
  }, [logoPosition]);
  const [showHuman, setShowHuman] = React.useState(false);

  const hex = hslToHex(hue, saturation, light);
  const hex2 = hslToHex(hue2, saturation2, light2);

  const handleChangeHex = (hex: string) => {
    const { h, s, l } = hexToHSL(hex);
    setHue(h);
    setSaturation(s);
    setLight(l);
  };
  const handleChangeHex2 = (hex: string) => {
    const { h, s, l } = hexToHSL(hex);
    setHue2(h);
    setSaturation2(s);
    setLight2(l);
  };

  return (
    <VirtualBgContext.Provider
      value={{
        backgroundType,
        backgroundTypeChange: setBackgroundType,
        backgroundImage,
        backgroundImageChange: setBackgroundImage,
        gradientAngle: deg,
        gradientAngleChange: setDeg,

        gradientHex: hex,
        gradientHexChange: handleChangeHex,
        gradientHex2: hex2,
        gradientHex2Change: handleChangeHex2,

        textDegree: textDeg,
        textDegreeChange: setTextDeg,
        text,
        textChange: setText,
        textScale,
        textScaleChange: setTextScale,
        textX: textXOffset,
        textXChange: setTextXOffset,
        textY: textYOffset,
        textYChange: setTextYOffset,
        textPosition: textPosition,
        textPositionChange: setTextPosition,
        textColor,
        textColorChange: setTextColor,
        aspectRatioText: aspectRatioText,
        aspectRatioTextChange: setAspectRatioText,
        aspectRatio: aspectRatio,

        logo,
        logoChange: setLogo,

        logoScale,
        logoScaleChange: setLogoScale,
        logoX: logoXOffset,
        logoXChange: setLogoXOffset,
        logoY: logoYOffset,
        logoYChange: setLogoYOffset,
        logoPosition,
        logoPositionChange: setLogoPosition,
        logoDegree: logoDeg,
        logoDegreeChange: setLogoDeg,

        showHuman,
        showHumanChange: setShowHuman,
      }}
    >
      {children}
    </VirtualBgContext.Provider>
  );
}
