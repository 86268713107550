import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

import usePortal from '../../utils/usePortal';
import { ClickAwayListener } from '../ClickAwayListener';
import { VerseBasePopoverVariantEnum } from './consts';
import { getPopoverTransformOrigin, getTranslate3d } from './helpers';
import {
  VerseBasePopoverComponentProps,
  VerseBasePopoverInnerContainerProps,
  VersePopoverTransformVariants,
} from './typings';

export const VerseBasePopover = ({
  anchorEl,
  open,
  onMouseEnter,
  onMouseLeave,
  onClose,
  placement = 'right-start',
  offsetX = 12,
  offsetY = 0,
  testId,
  width,
  height,
  maxWidth,
  maxHeight,
  padding = 1,
  children,
  className,
  zIndex,
  withArrow,
  clickAwayListenerProps,
  unmountOnClosed = true,
  variant,
  borderRadius,
  ariaLive,
  popoverStyles,
}: VerseBasePopoverComponentProps) => {
  const [positionedEl, setPositionedEl] = React.useState<HTMLElement | null>(
    null,
  );
  const [arrowRef, setArrowRef] = React.useState<HTMLDivElement | null>(null);
  const [delayedOpen, setDelayedOpen] = useState<boolean>(false);
  const portalRef = usePortal({ portalId: 'tray-popper-portal' });

  const {
    styles,
    attributes,
    update,
    state: offsetState,
  } = usePopper(anchorEl, positionedEl, {
    strategy: 'fixed',
    modifiers: [{ name: 'arrow', options: { element: arrowRef } }],
    placement,
  });

  // allows to override default styles.popper
  const getPopperStyles = () => {
    const latestPoppperStyles = { ...styles.popper };

    if (popoverStyles) {
      if (popoverStyles.transform) {
        const { transform, ...rest } = popoverStyles;
        const [x, y] = getTranslate3d(latestPoppperStyles.transform || '');
        const hasTranslateX = transform.includes('translateX');
        const hasTranslateY = transform.includes('translateY');

        const overrideTranslateX = hasTranslateX ? 0 : x || 0;
        const overrideTranslateY = hasTranslateY ? 0 : y || 0;

        latestPoppperStyles.transform = `translate(${overrideTranslateX}, ${overrideTranslateY}) ${transform}`;
        Object.assign(latestPoppperStyles, rest);
      }
    }

    return latestPoppperStyles;
  };

  useEffect(() => {
    update && update();
  }, [open, update]);

  useEffect(() => {
    setTimeout(() => {
      setDelayedOpen(Boolean(open));
    }, 1);
  }, [open]);

  const handleOnClose = (e: any) => {
    onClose && onClose(e);
  };

  if (open || !unmountOnClosed) {
    return (
      <>
        {createPortal(
          <RootContainer
            ref={setPositionedEl}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={getPopperStyles()}
            data-testid={testId}
            zIndex={zIndex}
            offsetX={offsetX}
            offsetY={offsetY}
            aria-live={ariaLive}
            {...attributes.popper}
          >
            <div>
              <AnimatedContainer
                open={Boolean(open && delayedOpen)}
                variants={getPopoverTransformOrigin(offsetState?.placement)}
              >
                {withArrow && (
                  <Arrow
                    ref={setArrowRef}
                    style={styles.arrow}
                    className="arrow"
                  />
                )}
                <ClickAwayListener
                  enabled={Boolean(open)}
                  onClickAway={handleOnClose}
                  {...clickAwayListenerProps}
                >
                  <StyledInnerContainer
                    width={width}
                    height={height}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                    padding={padding}
                    data-testid={testId}
                    className={className}
                    variant={variant}
                    borderRadius={borderRadius}
                  >
                    {children}
                  </StyledInnerContainer>
                </ClickAwayListener>
              </AnimatedContainer>
            </div>
          </RootContainer>,
          portalRef,
        )}
      </>
    );
  }
  return null;
};
const AnimatedContainer = styled.div<{
  open: boolean;
  variants: VersePopoverTransformVariants;
}>`
  transform-origin: ${({ open, variants }) => {
    return !open
      ? variants?.origin?.originX + ' ' + variants?.origin?.originY
      : variants?.scaled?.originX + ' ' + variants?.scaled?.originY;
  }};
  opacity: 0;
  opacity: ${({ open }) => (open ? '1' : '0')};
  transition: opacity ${({ theme }) => theme.animationSpeed}ms ease-in-out;
`;

const Arrow = styled.div`
  width: 10px;
  height: 10px;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;

    width: 10px;
    height: 10px;
    background-color: white;
    border-top: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark20};
    border-right: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark20};
  }
`;

const RootContainer = styled.div<VerseBasePopoverInnerContainerProps>`
  && {
    z-index: ${({ zIndex, theme }) =>
      zIndex ? zIndex : theme.zIndex.level.popup};

    padding-top: ${({ offsetY }) => (offsetY ? `${offsetY}px` : '0')};
    padding-bottom: ${({ offsetY }) => (offsetY ? `${offsetY}px` : '0')};
    padding-right: ${({ offsetX }) => (offsetX ? `${offsetX}px` : '0')};
    padding-left: ${({ offsetX }) => (offsetX ? `${offsetX}px` : '0')};

    &[data-popper-placement^='top'] ${Arrow} {
      bottom: -4px;
      :after {
        transform: rotate(135deg);
      }
    }
    &[data-popper-placement^='right'] ${Arrow} {
      left: -4px;
      :after {
        transform: rotate(-135deg);
      }
    }
    &[data-popper-placement^='left'] ${Arrow} {
      right: -4px;
      :after {
        transform: rotate(45deg);
      }
    }
    &[data-popper-placement^='bottom'] ${Arrow} {
      top: -4px;
      :after {
        transform: rotate(-45deg);
      }
    }
  }
`;

const StyledInnerContainer = styled.div<VerseBasePopoverInnerContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ padding, theme }) =>
    `padding: ${
      typeof padding === 'number' && Number.isFinite(padding)
        ? `${theme.spacing(padding)}px`
        : padding
    };`};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : `16px`};
  ${({ width = 'auto' }) =>
    `width: ${Number.isInteger(width) ? `${width}px` : width};`}
  ${({ maxWidth = 'auto' }) =>
    `max-width: ${Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth};`}
  ${({ height = 'auto' }) =>
    `height: ${Number.isInteger(height) ? `${height}px` : height};`}
  ${({ maxHeight = 'auto' }) =>
    `max-height: ${
      Number.isInteger(maxHeight) ? `${maxHeight}px` : maxHeight
    };`}

  z-index: ${({ theme }) => theme.zIndex.level.popup};

  box-shadow: ${({ theme }) => theme.shadows.primaryDark10};

  background: ${({ variant, theme }) => {
    switch (variant) {
      case VerseBasePopoverVariantEnum.BLUE:
        return theme.colors.other.Blue10;

      case VerseBasePopoverVariantEnum.WHITE:
      default:
        return theme.colors.other.White;
    }
  }};

  border: 1px solid
    ${({ variant, theme }) => {
      switch (variant) {
        case VerseBasePopoverVariantEnum.BLUE:
          return theme.colors.other.Blue75;

        case VerseBasePopoverVariantEnum.WHITE:
        default:
          return theme.colors.main.PrimaryDark20RGBA;
      }
    }};
`;
