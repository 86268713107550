import React from "react";
import { VerseIconSVGProps } from "../typings";

export const PollStandardIcon = ({ fill }: VerseIconSVGProps) => (
  <>
    <rect
      x="1.5"
      y="4.5"
      width="21"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="1.5"
      y="4.5"
      width="21"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="1.5"
      y="10.125"
      width="21"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="1.5"
      y="10.125"
      width="21"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="1.5"
      y="15.75"
      width="21"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="1.5"
      y="15.75"
      width="21"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
  </>
);
