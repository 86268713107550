import React from "react";
import { useController } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";

import { VerseTheme } from "../../theme";
import { VerseSelectSizeEnum } from "./consts";
import { VerseSelectComponentProps } from "./typings";
import { VerseSelectOptionObj } from "./typings/verseSelectTypings";
import { VerseSelectGroup } from "./VerseSelectGroup";
import { VerseSelectGroupHeading } from "./VerseSelectGroupHeading";
import { VerseSelectIndicator } from "./VerseSelectIndicator";
import { VerseSelectMenuList } from "./VerseSelectMenuList";
import { VerseSelectOption } from "./VerseSelectOption";
import { VerseSelectPlaceholder } from "./VerseSelectPlaceholder";
import { VerseSelectSingleValue } from "./VerseSelectSingleValue";

export function VerseSelect({
  options,
  size = VerseSelectSizeEnum.MEDIUM,
  placeholder = "Select...",
  name,
  control,
  defaultValue,
  rules,
  onChange,
  disabled,
  reactSelectProps,
  isSearchable = false,
  ...other
}: VerseSelectComponentProps) {
  const {
    field: { ref, value, onChange: defaultOnChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const combinedOnChange = (optionObj: VerseSelectOptionObj) => {
    defaultOnChange(optionObj);
    onChange && onChange(optionObj);
  };

  return (
    <StyledSelect
      {...inputProps}
      isSearchable={isSearchable}
      inputRef={ref}
      styles={customStyles}
      value={value}
      onChange={combinedOnChange}
      options={options}
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={placeholder}
      components={{
        Option: VerseSelectOption,
        Group: VerseSelectGroup,
        GroupHeading: VerseSelectGroupHeading,
        IndicatorSeparator: null,
        IndicatorsContainer: VerseSelectIndicator,
        SingleValue: VerseSelectSingleValue,
        Placeholder: VerseSelectPlaceholder,
        MenuList: VerseSelectMenuList,
        MultiValueContainer: ({
          selectProps,
          data,
        }: {
          selectProps: VerseSelectComponentProps;
          data: VerseSelectOptionObj;
        }) => {
          const values = selectProps.value;
          let text = "";
          if (values) {
            text =
              values[values.length - 1].label === data.label
                ? data.label
                : data.label + ", ";
          }
          return text;
        },
      }}
      menuPortalTarget={document.body}
      size={size}
      isDisabled={disabled}
      menuShouldScrollIntoView={false}
      {...reactSelectProps}
      {...other}
    />
  );
}
const customStyles = {
  groupHeading: () => ({
    padding: 0,
  }),
  menu: (provided: CSSStyleDeclaration) => ({
    ...provided,
    border: `1px solid ${VerseTheme.colors.main.PrimaryDark20}`,
    padding: 0,
    borderRadius: 12,
    boxShadow: `0.5px 0.5px 0px 0px ${VerseTheme.colors.main.PrimaryDark100}`,
  }),
  menuPortal: (provided: CSSStyleDeclaration) => ({
    ...provided,
    zIndex: VerseTheme.zIndex.level.dialogPopup + 4,
    transform: "all 0.15s linear",
  }),
};
const StyledSelect = styled(Select)`
  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  ${({ width }) =>
    width && `width: ${typeof width === "string" ? width : `${width}px`};`}

  .react-select__placeholder {
    color: ${({ theme }) => theme.colors.main.PrimaryDark70};
  }

  .react-select__indicators {
    svg {
      stroke: ${({ theme }) => theme.colors.main.PrimaryDark100};
    }
  }
  .react-select__control {
    border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark20};
    min-height: unset;
    transform: all 0.15s linear;

    ${({ size }) => {
      switch (size) {
        case VerseSelectSizeEnum.SMALL:
          return `
            height: 32px;
            border-radius: 10px;
            padding: 0 4px 0 12px;
            `;
        case VerseSelectSizeEnum.MEDIUM:
          return `
            height: 40px;
            border-radius: 12px;
            padding: 0 8px 0 16px;
            `;
        case VerseSelectSizeEnum.LARGE:
          return `
            height: 48px;
            border-radius: 16px;
            padding: 0 12px 0 20px;
            `;
        default:
          return ``;
      }
    }}

    :hover {
      border-color: ${({ theme }) => theme.colors.main.PrimaryDark100};
      ${({ theme }) => theme.helpers.hover.elevate}
    }
    :active {
      transform: unset;
      box-shadow: unset;
    }
  }
  .react-select__control--is-focused {
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark100};
    box-shadow: unset;
  }
  .react-select__control--menu-is-open {
    ${({ theme }) => theme.helpers.hover.elevate}

    .react-select__indicators {
      transform: rotate(-180deg);
    }
  }

  .react-select__placeholder {
    svg {
      stroke: ${({ theme }) => theme.colors.main.PrimaryDark70};
    }
  }
  .react-select__control--is-disabled {
    background-color: ${({ theme }) => theme.colors.main.PrimaryDark03};
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark20};

    .react-select__indicators,
    .react-select__value-container {
      svg {
        stroke: ${({ theme }) => theme.colors.main.PrimaryDark30};
      }
    }
    .react-select__placeholder {
      color: ${({ theme }) => theme.colors.main.PrimaryDark30};
    }
  }
  .react-select__value-container--is-multi {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .react-select__value-container {
    padding: 0;
    font-family: GTEestiDisplay;
    font-weight: 300;

    ${({ size }) => {
      switch (size) {
        case VerseSelectSizeEnum.SMALL:
          return `font-size: 14px;`;
        case VerseSelectSizeEnum.MEDIUM:
          return `font-size: 15px;`;
        case VerseSelectSizeEnum.LARGE:
          return `font-size: 16px;`;
        default:
          return ``;
      }
    }}
  }
`;
