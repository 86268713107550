import React from 'react';
import styled from 'styled-components';

import { VerseBasePopover } from '..';
import { PickerWrapper } from './EmojiMartStyledComponents';
import { VerseEmojiMartPickerPopoverProps } from './typings';

const { Picker } = require('emoji-mart-virtualized');
/**
 * VerseEmojiMartPickerPopover
 * ---
 * @description Emoji picker extended from Material UI `Select` component,
 * injected with Verse UI styling.
 */
export const VerseEmojiMartPickerPopover = ({
  handleEmojiSelect,
  placement = 'top',
  ...other
}: VerseEmojiMartPickerPopoverProps) => {
  return (
    <EmojiMartPopover placement={placement} {...other}>
      <PickerWrapper>
        <Picker
          showPreview={false}
          showSkinTones={false}
          onSelect={handleEmojiSelect}
        />
      </PickerWrapper>
    </EmojiMartPopover>
  );
};

export const EmojiMartPopover = styled(VerseBasePopover)`
  && {
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
  }
`;
