import React from 'react';
import styled from 'styled-components';

import { VerseButtonTextLarge, Subtitle1 } from '../VerseTypography';
import { VerseShortcutBadge } from '../VerseShortcutBadge';
import { VerseTypographyVariantEnum } from '../VerseTypography/consts';
import {
  ClickableTabUnderlineContainerProps,
  VerseTabUnderlineComponentProps,
} from './typings';
import { VerseBox } from '../VerseBox';

export function VerseTabUnderline({
  title,
  testId,
  typography,
  titleIndicatorGap = 1,
  shortcutKey,
  selected,
  ...other
}: VerseTabUnderlineComponentProps) {
  const TextComponent = React.useMemo(() => {
    switch (typography) {
      case VerseTypographyVariantEnum.SUBTITLE1:
        return Subtitle1;
      default:
        return VerseButtonTextLarge;
    }
  }, [typography]);

  return (
    <ClickableTabUnderlineContainer
      {...other}
      selected={selected}
      data-testid={testId}>
      <RowContainer mb={titleIndicatorGap}>
        <TextComponent component="span">{title}</TextComponent>

        {shortcutKey ? (
          <VerseShortcutBadge shortcutKey={shortcutKey} ml={1} />
        ) : null}
      </RowContainer>
      <ActiveIndicator />
    </ClickableTabUnderlineContainer>
  );
}

const RowContainer = styled(VerseBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActiveIndicator = styled.div`
  height: 4px;
  border-radius: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.main.PrimaryDark100};
  transition: opacity ${({ theme }) => theme.animationSpeed}ms linear;
`;

const ClickableTabUnderlineContainer = styled.div<ClickableTabUnderlineContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: transparent;
  cursor: pointer;

  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  > ${ActiveIndicator} {
    opacity: ${({ selected }) => (selected ? 1 : 0)};
  }
  > span {
    transition: opacity ${({ theme }) => theme.animationSpeed}ms linear;
    opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  }

  ${({ m, theme }) => m && `margin: ${theme.spacing(m)}px;`}
  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  :hover {
    > span {
      opacity: 1;
    }
  }
`;
