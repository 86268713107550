import { VerseBox } from "src/verse/src";

export function ButterLogoLong(props) {
  const { size = 32, boxProps } = props;

  return (
    <VerseBox
      component="svg"
      width={size}
      {...boxProps}
      viewBox="0 0 107 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12.3776V9.84157C3 8.72037 3.64503 7.6992 4.6575 7.21753L12.9303 3.28182C13.552 2.98603 14.2586 2.92062 14.9241 3.09725L18.5096 4.04889C19.7833 4.38694 20.67 5.53975 20.67 6.85751V9.49157C20.67 10.4795 20.1681 11.3998 19.3375 11.9347L11.4325 17.0254C10.5303 17.6064 9.3812 17.6429 8.44391 17.1202L4.49056 14.9155C3.57034 14.4024 3 13.4313 3 12.3776Z"
        fill={"#FFFD63"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.3776V9.84158C0 7.56285 1.31097 5.48742 3.36869 4.50847L11.6415 0.572767C12.9051 -0.0284017 14.3412 -0.161339 15.6937 0.197645L19.2792 1.14929C21.8678 1.83635 23.67 4.1793 23.67 6.85752V9.49158C23.67 11.4994 22.6499 13.3698 20.9618 14.4569L13.0568 19.5476C11.2231 20.7285 8.8877 20.8026 6.98276 19.7403L3.02942 17.5357M0 12.3776C0 14.519 1.15916 16.4927 3.02941 17.5357L0 12.3776ZM3 9.84158V12.3776C3 13.4313 3.57034 14.4024 4.49056 14.9155L8.44391 17.1202C9.3812 17.6429 10.5303 17.6064 11.4325 17.0254L19.3375 11.9347C20.1681 11.3998 20.67 10.4795 20.67 9.49158V6.85752C20.67 5.53975 19.7833 4.38695 18.5096 4.04889L14.9241 3.09725C14.2586 2.92062 13.552 2.98603 12.9303 3.28182L4.6575 7.21753C3.64503 7.6992 3 8.72038 3 9.84158Z"
        fill="black"
      />
      <path
        d="M101.595 8.4593C101.228 8.38593 101.375 7.94572 101.571 7.55442C101.766 7.16312 101.913 6.72291 101.913 6.2827V6.14864C101.913 5.89852 101.71 5.69575 101.46 5.69575H98.4529C98.2028 5.69575 98 5.89852 98 6.14864V18.1557C98 18.4059 98.2028 18.6086 98.4529 18.6086H101.54C101.79 18.6086 102 18.4059 102 18.1557V13.5951C102 10.4727 102.956 9.14859 106.122 9.24207C106.385 9.24981 106.609 9.04355 106.609 8.78115V5.95299C106.609 5.70287 106.404 5.4991 106.154 5.50706C103.428 5.59391 102.731 6.49176 102.011 8.11691C101.937 8.28811 101.815 8.50821 101.595 8.4593Z"
        fill={"black"}
      />
      <path
        d="M96.3042 11.7611C96.3042 7.3101 93.1004 5.30469 89.8233 5.30469C85.4945 5.30469 83 8.14161 83 12.1524C83 15.9676 85.3967 19.0002 89.8967 19.0002C93.2704 19.0002 95.2396 17.0716 95.9207 14.8446C96.0048 14.5696 95.7893 14.3046 95.5017 14.3046H92.4975C92.3026 14.3046 92.1333 14.4314 92.0497 14.6075C91.6801 15.3859 90.96 15.8698 89.8722 15.8698C88.4137 15.8698 87.3815 14.9978 86.9825 13.6506C86.9032 13.3827 87.118 13.1307 87.3974 13.1307H95.8343C96.0613 13.1307 96.2527 12.9625 96.2687 12.7361C96.2924 12.4017 96.3042 12.0163 96.3042 11.7611ZM89.8967 8.33726C91.1809 8.33726 92.0971 9.03471 92.3865 10.2917C92.4464 10.5517 92.234 10.7829 91.9672 10.7829H87.5201C87.2324 10.7829 87.0153 10.5167 87.1072 10.2441C87.4837 9.12662 88.2863 8.33726 89.8967 8.33726Z"
        fill={"black"}
      />
      <path
        d="M71.5504 8.82592C71.8005 8.82592 72.0004 8.62315 72.0004 8.37303V6.14842C72.0004 5.89829 71.8005 5.69552 71.5504 5.69552H69.3641C69.1139 5.69552 69.0004 5.49276 69.0004 5.24263V2.62672C69.0004 2.37659 68.8005 2.17383 68.5504 2.17383H65.4511C65.201 2.17383 64.9982 2.3766 64.9982 2.62672V5.24263C64.9982 5.49276 64.7954 5.69552 64.5453 5.69552H63.1033C62.8532 5.69552 62.6504 5.89829 62.6504 6.14842V8.37303C62.6504 8.62315 62.8532 8.82592 63.1033 8.82592H64.5453C64.7954 8.82592 64.9982 9.02869 64.9982 9.27881V14.6465C64.9982 17.7524 66.221 18.6084 68.8134 18.6084H71.98C72.2301 18.6084 72.4329 18.4056 72.4329 18.1555V15.9309C72.4329 15.6808 72.2301 15.478 71.98 15.478H70.0851C68.9601 15.478 69.0004 15.4291 69.0004 14.2063V9.27881C69.0004 9.02869 69.1139 8.82592 69.3641 8.82592H71.5504Z"
        fill={"black"}
      />
      <path
        d="M81.55 8.82592C81.8001 8.82592 82 8.62315 82 8.37303V6.14842C82 5.89829 81.8001 5.69552 81.55 5.69552H79.3637C79.1136 5.69552 79 5.49276 79 5.24263V2.62672C79 2.37659 78.8001 2.17383 78.55 2.17383H75.4507C75.2006 2.17383 74.9978 2.3766 74.9978 2.62672V5.24263C74.9978 5.49276 74.7951 5.69552 74.5449 5.69552H73.45C73.1999 5.69552 73 5.89829 73 6.14842V8.37303C73 8.62315 73.1999 8.82592 73.45 8.82592H74.5449C74.7951 8.82592 74.9978 9.02869 74.9978 9.27881V14.6465C74.9978 17.7524 76.2206 18.6084 78.813 18.6084H81.9796C82.2297 18.6084 82.4325 18.4056 82.4325 18.1555V15.9309C82.4325 15.6808 82.2297 15.478 81.9796 15.478H80.0847C78.9597 15.478 79 15.4291 79 14.2063V9.27881C79 9.02869 79.1136 8.82592 79.3637 8.82592H81.55Z"
        fill={"black"}
      />
      <path
        d="M52.6467 18.9995C54.8722 18.9995 56.2907 17.2386 56.9265 15.9669C57.0243 15.7713 57.1466 15.4778 57.4401 15.6001C57.758 15.7224 57.5135 16.2115 57.3178 16.6028C57.1222 16.9941 56.9999 17.4098 56.9999 17.9968V18.1553C56.9999 18.4054 57.2027 18.6082 57.4528 18.6082H60.46C60.7101 18.6082 61 18.4054 61 18.1553V6.45C61 6.19987 60.7101 6 60.46 6H57.4528C57.2027 6 56.9999 6.19987 56.9999 6.45V10.6599C56.9999 13.6436 55.557 15.6734 53.9673 15.6734C52.5733 15.6734 52 14.8664 52 11.9561V6.45C52 6.19987 51.7102 6 51.4601 6H48.4529C48.2028 6 48 6.19987 48 6.45V13.5213C48 17.1897 49.8587 18.9995 52.6467 18.9995Z"
        fill={"black"}
      />
      <path
        d="M37 1.45289C37 1.20277 36.7901 1 36.54 1H33.4529C33.2028 1 33 1.20277 33 1.45289V18.1556C33 18.4057 33.2028 18.6085 33.4529 18.6085H36.4601C36.7102 18.6085 37 18.4057 37 18.1556V18.1438C37 17.777 36.8152 17.4346 36.6195 17.0922C36.4239 16.6764 36.2038 16.2607 36.5706 16.1384C36.8152 16.065 36.9375 16.334 37.0108 16.4808C37.8179 18.0215 39.1385 18.9998 41.0706 18.9998C44.6167 18.9998 46.6955 15.9672 46.6955 12.152C46.6955 8.33686 44.6167 5.30429 41.0706 5.30429C39.1141 5.30429 37.8179 6.307 37.0108 7.79883C36.913 7.97002 36.7418 8.31241 36.4483 8.16567C36.1304 8.01893 36.4483 7.57872 36.644 7.16297C36.8152 6.82058 37 6.45374 37 6.08689V1.45289ZM39.9456 15.6737C38.0869 15.6737 36.913 14.0352 36.913 12.152C36.913 10.2689 38.0869 8.63034 39.9456 8.63034C41.9021 8.63034 42.7825 10.2689 42.7825 12.152C42.7825 14.0352 41.9021 15.6737 39.9456 15.6737Z"
        fill={"black"}
      />
    </VerseBox>
  );
}
