import {
  VerseBody2,
  VerseBox,
  VerseInput,
  VerseInputSizeEnum,
  VerseSubtitle3,
} from "src/verse/src";

import { useVirtualBgContext } from "src/context/VirtualBgContext";

import { useForm } from "react-hook-form";
import styled from "styled-components";
export function TextInput() {
  const { text, textChange } = useVirtualBgContext();
  const { control } = useForm();

  return (
    <VerseBox>
      <VerseBody2 mb={1}>Custom text</VerseBody2>
      <StyledInput
        control={control}
        name="text-input"
        testId="text-input"
        defaultValue={text}
        onChange={(e) => textChange?.(e.target.value)}
        size={VerseInputSizeEnum.MEDIUM}
      />
    </VerseBox>
  );
}
const StyledInput = styled(VerseInput)`
  #input_wrapper {
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark20};
    border-radius: 12px;
  }
`;
