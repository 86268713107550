import React, { useContext } from "react";
import { PositionEnum } from "src/consts/position";
import { BackgroundTypeEnum } from "src/consts/background";

export interface VirtualBgContextState {
  backgroundType: BackgroundTypeEnum;
  backgroundTypeChange?: (backgroundType: BackgroundTypeEnum) => void;

  backgroundImage?: string;
  backgroundImageChange?: (backgroundImage: string) => void;

  gradientAngle: number;
  gradientAngleChange?: (value: number) => void;

  gradientHex: string;
  gradientHexChange?: (value: string) => void;
  gradientHex2: string;
  gradientHex2Change?: (value: string) => void;

  textDegree: number;
  textDegreeChange?: (value: number) => void;
  text: string;
  textChange?: (value: string) => void;
  textScale: number;
  textScaleChange?: (value: number) => void;
  textX: number;
  textXChange?: (value: number) => void;
  textY: number;
  textYChange?: (value: number) => void;
  textPosition: PositionEnum;
  textPositionChange?: (value: PositionEnum) => void;
  textColor: string;
  textColorChange?: (value: string) => void;

  aspectRatioText: string;
  aspectRatioTextChange?: (value: string) => void;
  aspectRatio: number;

  logo: string | null;
  logoChange?: (value: string | null) => void;
  logoScale: number;
  logoScaleChange?: (value: number) => void;
  logoX: number;
  logoXChange?: (value: number) => void;
  logoY: number;
  logoYChange?: (value: number) => void;
  logoPosition: PositionEnum;
  logoPositionChange?: (value: PositionEnum) => void;
  logoDegree: number;
  logoDegreeChange?: (value: number) => void;

  showHuman: boolean;
  showHumanChange?: (value: boolean) => void;
}

export const initState: VirtualBgContextState = {
  backgroundType: BackgroundTypeEnum.GRADIENT,
  backgroundTypeChange: undefined,
  backgroundImage: undefined,
  gradientAngle: 180,
  gradientAngleChange: undefined,

  gradientHex: "#f7f76e",
  gradientHexChange: undefined,
  gradientHex2: "#f4d371",
  gradientHex2Change: undefined,

  textDegree: 0,
  textDegreeChange: undefined,
  text: "Custom text here",
  textChange: undefined,
  textScale: 1,
  textScaleChange: undefined,
  textX: 0,
  textXChange: undefined,
  textY: 0,
  textYChange: undefined,
  textPosition: PositionEnum.TOP_LEFT,
  textPositionChange: undefined,
  textColor: "#000000",
  textColorChange: undefined,

  aspectRatioText: "4:3",
  aspectRatioTextChange: undefined,
  aspectRatio: 4 / 3,

  logo: "https://files.butter.us/butter-logo-nobg-512.png",
  logoChange: undefined,

  logoScale: 1,
  logoScaleChange: undefined,
  logoX: 0,
  logoXChange: undefined,
  logoY: 0,
  logoYChange: undefined,
  logoPosition: PositionEnum.BOTTOM_RIGHT,
  logoPositionChange: undefined,
  logoDegree: 0,
  logoDegreeChange: undefined,

  showHuman: true,
  showHumanChange: undefined,
};

export const VirtualBgContext =
  React.createContext<VirtualBgContextState>(initState);
export const useVirtualBgContext = () => useContext(VirtualBgContext);
