import { VerseBody2, VerseSelect, VerseSelectSizeEnum } from "src/verse/src";

import { useForm } from "react-hook-form";
import { useVirtualBgContext } from "src/context/VirtualBgContext";

const ASPECT_RATIO_OPTIONS = [
  {
    label: "4:3 (Butter ratio)",
    value: "4:3",
  },
  {
    label: "16:9",
    value: "16:9",
  },
];

export function AspectRatioSelector() {
  const { aspectRatioText, aspectRatioTextChange } = useVirtualBgContext();
  const { control } = useForm();

  return (
    <>
      <VerseBody2 mb={1.5}>Aspect ratio</VerseBody2>
      <VerseSelect
        options={ASPECT_RATIO_OPTIONS}
        onChange={(e) => {
          aspectRatioTextChange?.(e.value);
        }}
        value={ASPECT_RATIO_OPTIONS.find((x) => x.value === aspectRatioText)}
        name="aspectRatio"
        testId="aspectRatio"
        control={control}
        width="100%"
        size={VerseSelectSizeEnum.MEDIUM}
      />
    </>
  );
}
