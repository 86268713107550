import React from 'react';
import styled from 'styled-components';

import { VerseBasePopover, VerseBody1, VerseButtonTextMedium } from '..';
import { useVerseTheme } from '../..';
import { VerseIcon } from '../VerseIcon';
import { VerseIconIdEnum } from '../VerseIcon/consts';
import { VerseMenuButton } from '../VerseMenuButton';
import {
  VerseSplitButtonSizeEnum,
  VerseSplitButtonVariantEnum,
} from './consts';
import {
  MenuButtonContainerProps,
  SplitSingleButtonContainerProps,
  VerseSplitButtonComponentProps,
  VerseSplitButtonContainerProps,
} from './typings';

export const VerseSplitButton = ({
  variant = VerseSplitButtonVariantEnum.PRIMARY_BLACK,
  size = VerseSplitButtonSizeEnum.MEDIUM,
  popoverProps,
  dropdownList,
  primaryButton,
  menuButton,
  containerRef,
  ...containerProps
}: VerseSplitButtonComponentProps) => {
  const [open, setOpen] = React.useState(false);
  const iconRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen(old => !old);
  };
  const closePopover = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e?.stopPropagation();
    setOpen(false);
  };

  const theme = useVerseTheme();
  const chevronStrokeColor = React.useMemo(() => {
    switch (variant) {
      case VerseSplitButtonVariantEnum.PRIMARY_BLACK:
        return theme.colors.other.White;
      case VerseSplitButtonVariantEnum.PRIMARY_YELLOW:
      case VerseSplitButtonVariantEnum.PRIMARY_WHITE:
      default:
        return theme.colors.main.PrimaryDark100;
    }
  }, [variant]);

  return (
    <>
      <VerseBasePopover
        onClose={closePopover}
        anchorEl={iconRef.current}
        open={open}
        width="fit-content"
        offsetY={10}
        {...popoverProps}
      >
        {dropdownList.map(item => {
          const combinedOnClick = (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          ) => {
            item.onClick();
            closePopover(e);
          };

          return (
            <VerseMenuButton
              key={item.iconId}
              height={48}
              onClick={combinedOnClick}
              width="100%"
              disabled={item?.disabled}
              testId={item?.testId}
            >
              <VerseIcon iconId={item.iconId} mr={1} size={16} />
              <VerseBody1>{item.title}</VerseBody1>
            </VerseMenuButton>
          );
        })}
      </VerseBasePopover>

      <StyledContainer
        ref={containerRef}
        variant={variant}
        size={size}
        {...containerProps}
      >
        <PrimaryButtonContainer
          variant={variant}
          disabled={primaryButton.disabled}
          onClick={primaryButton.onClick}
        >
          <VerseButtonTextMedium
            component="span"
            testId={primaryButton?.testId}
          >
            {primaryButton.title}
          </VerseButtonTextMedium>
        </PrimaryButtonContainer>

        <MenuButtonContainer
          ref={iconRef}
          variant={variant}
          onClick={handleToggle}
          popoverOpen={open}
          disabled={menuButton?.disabled}
        >
          <VerseIcon
            iconId={
              popoverProps?.placement?.includes('bottom')
                ? VerseIconIdEnum.CHEVRON_DOWN
                : VerseIconIdEnum.CHEVRON_UP
            }
            size={menuButton?.iconSize ?? 16}
            stroke={chevronStrokeColor}
          />
        </MenuButtonContainer>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div<VerseSplitButtonContainerProps>`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark20};

  position: relative;
  overflow: hidden;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  ${({ withShadow, theme }) =>
    withShadow && `box-shadow: ${theme.shadows.primaryDark07}`};

  ${({ width = 'fit-content' }) =>
    width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}

  ${({ size }) => {
    switch (size) {
      case VerseSplitButtonSizeEnum.MEDIUM:
        return `
            height: 40px;
            border-radius: 12px;
        `;
      case VerseSplitButtonSizeEnum.LARGE:
        return `
            height: 48px;
            border-radius: 16px;
        `;
      default:
        return ``;
    }
  }}

${({ height }) =>
    height
      ? `height: ${Number.isInteger(height) ? `${height}px` : height};`
      : ''}

  ${({ variant, theme }) => {
    switch (variant) {
      case VerseSplitButtonVariantEnum.PRIMARY_BLACK:
        return `
            background-color: ${theme.colors.main.PrimaryDark100};
            color: ${theme.colors.other.White};
        `;
      case VerseSplitButtonVariantEnum.PRIMARY_YELLOW:
        return `
            background-color: ${theme.colors.main.PrimaryYellow100};
        `;
      case VerseSplitButtonVariantEnum.PRIMARY_WHITE:
        return `
            background-color: ${theme.colors.other.White};
        `;
      default:
        return ``;
    }
  }}
`;

const SplitSingleButtonBase = styled.div<SplitSingleButtonContainerProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ variant, theme }) => {
    switch (variant) {
      case VerseSplitButtonVariantEnum.PRIMARY_BLACK:
        return `:hover { background-color: ${theme.colors.main.PrimaryDark90}; }`;
      case VerseSplitButtonVariantEnum.PRIMARY_YELLOW:
        return `:hover { background-color: ${theme.colors.main.PrimaryYellowButtonHover}; }`;
      case VerseSplitButtonVariantEnum.PRIMARY_WHITE:
        return `:hover { background-color: ${theme.colors.main.PrimaryDark03}; }`;
      default:
        return ``;
    }
  }}

  ${({ disabled }) =>
    disabled && `pointer-events: none; span, svg {opacity: 0.2;}`}
`;
const PrimaryButtonContainer = styled(SplitSingleButtonBase)`
  position: relative;
  padding: 0 12px 0 16px;
  margin-right: 1px;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    width: 1px;

    ${({ variant, theme }) => {
      switch (variant) {
        case VerseSplitButtonVariantEnum.PRIMARY_BLACK:
          return `background-color: ${theme.colors.other.White15RGBA};`;
        case VerseSplitButtonVariantEnum.PRIMARY_YELLOW:
        case VerseSplitButtonVariantEnum.PRIMARY_WHITE:
        default:
          return `background-color: ${theme.colors.main.PrimaryDark20};`;
      }
    }}
  }
`;
const MenuButtonContainer = styled(
  SplitSingleButtonBase,
)<MenuButtonContainerProps>`
  padding: 0 12px;

  svg {
    transform: ${({ popoverOpen }) =>
      popoverOpen ? `rotate(-180deg)` : ` rotate(0)`};
    transition: transform ${({ theme }) => theme.animationSpeed}ms linear;
  }
`;
