import React from 'react';

import { VerseTypographyFontWeightEnum } from '../../consts';
import {
  VerseTypographyComponentProps,
  VerseTypographyVariantProps,
} from '../../typings';
import { WrappedVerseTypography } from '../../WrappedVerseTypography';

export const VerseLabelText = (props: VerseTypographyComponentProps) => {
  const variantProps: VerseTypographyVariantProps = {
    fontSize: 12,
    lineHeight: 1.2,
    fontWeight: VerseTypographyFontWeightEnum.MEDIUM,
    letterSpacing: '0.05em',
    opacity: 0.8,
  };

  return <WrappedVerseTypography variantProps={variantProps} {...props} />;
};
