import React from 'react';

import { VerseTypographyFontWeightEnum } from '../../consts';
import {
  VerseTypographyComponentProps,
  VerseTypographyVariantProps,
} from '../../typings';
import { WrappedVerseTypography } from '../../WrappedVerseTypography';

export const VerseButtonTextSmall = (props: VerseTypographyComponentProps) => {
  const variantProps: VerseTypographyVariantProps = {
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: VerseTypographyFontWeightEnum.NORMAL,
    letterSpacing: '0.006em',
  };
  return <WrappedVerseTypography variantProps={variantProps} {...props} />;
};
