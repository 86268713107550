import React from "react";
import { VerseIconSVGProps } from "../typings";

export const PollWordCloudIcon = ({ fill }: VerseIconSVGProps) => (
  <>
    <rect
      x="8.3999"
      y="2.25"
      width="6.375"
      height="3.75"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="0.899902"
      y="7.125"
      width="12"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="14.0249"
      y="7.125"
      width="9"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="0.899902"
      y="12.375"
      width="6.75"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="8.3999"
      y="17.625"
      width="6.75"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="8.7749"
      y="12.375"
      width="12"
      height="4.125"
      rx="1.5"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
  </>
);
