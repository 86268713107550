// @refresh reset
import "./quillSnow.css";

import loadable from "@loadable/component";
import React from "react";

export const VerseWysiwyg = loadable(() => import("./VerseWysiwygComponent"), {
  resolveComponent: (module) => module.VerseWysiwygComponent,
  fallback: <></>,
});

export const LOADER_CONTAINER_MOTION_PROPS = {
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 0.5 },
  },
  initial: "hidden",
  animate: "visible",
  exit: "hidden",
  transition: { type: "linear", duration: 0.25 },
};
