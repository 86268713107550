import React from 'react';
import { VerseTypographyFontWeightEnum } from '../consts';

import {
  VerseTypographyComponentProps,
  VerseTypographyVariantProps,
} from '../typings';
import { WrappedVerseTypography } from '../WrappedVerseTypography';

export const TileDisplayNameText = (props: VerseTypographyComponentProps) => {
  const variantProps: VerseTypographyVariantProps = {
    fontSize: 10,
    lineHeight: 1,
    letterSpacing: '0.006em',
    fontWeight: VerseTypographyFontWeightEnum.NORMAL,
  };
  return <WrappedVerseTypography variantProps={variantProps} {...props} />;
};
