import { VerseBody2, VerseBox, VerseSubtitle3 } from "src/verse/src";
import { StyledRangeInput } from "src/components/FormElements/StyledRangeInput";
import { useVirtualBgContext } from "src/context/VirtualBgContext";
export function LogoYInput() {
  const { logoY, logoYChange } = useVirtualBgContext();
  return (
    <VerseBox>
      <VerseBody2 mb={1}>Y offset</VerseBody2>
      <StyledRangeInput
        value={logoY}
        onChange={(evt) => logoYChange?.(Number(evt.target.value))}
        min="-50"
        max="80"
      />
    </VerseBox>
  );
}
