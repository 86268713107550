import { VerseBox, VerseH2 } from "src/verse/src";
import styled, { createGlobalStyle } from "styled-components";

import { VBGPreview } from "src/components/VBGPreview";
import { VirtualBgContextWrapper } from "src/context/VirtualBgContextWrapper";
import { VirtualBgGeneratorSidebar } from "./VirtualBgGeneratorSidebar";
import { MAX_HEIGHT_SIDEBAR, MOBILE_BREAKPOINT_WIDTH } from "src/consts/layout";
import { VirtualBgGeneratorCTA } from "./VirtualBgGeneratorCTA";
import { VirtualBgGeneratorHeader } from "./VirtualBgGeneratorHeader";
const Reset = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }
`;

export function VirtualBgGenerator() {
  return (
    <VirtualBgContextWrapper>
      <Reset />
      <VirtualBgGeneratorHeader />
      <VerseBox m={5} mt={6} display="flex" justifyContent="center">
        <VerseH2>Virtual background creator</VerseH2>
      </VerseBox>

      <FullScreen>
        <StyledResponsive>
          <StyledResponsivePreview>
            <VBGPreview />
          </StyledResponsivePreview>
          <VirtualBgGeneratorSidebar />
        </StyledResponsive>

        <VerseBox position="relative" mt={9} pb={8}>
          <VirtualBgGeneratorCTA />
        </VerseBox>
      </FullScreen>
    </VirtualBgContextWrapper>
  );
}

const StyledResponsive = styled.div`
  display: flex;
  @media (max-width: ${MOBILE_BREAKPOINT_WIDTH - 1}px) {
    flex-direction: column;
  }
  @media (min-width: ${MOBILE_BREAKPOINT_WIDTH}px) {
    height: ${MAX_HEIGHT_SIDEBAR}px;
    align-items: center;
    justify-content: center;
  }
`;
const StyledResponsivePreview = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT_WIDTH - 1}px) {
    margin-bottom: 32px;
    position: sticky;
    width: 100%;
    top: 12px;
    z-index: 1;
  }
  @media (min-width: ${MOBILE_BREAKPOINT_WIDTH}px) {
    margin-right: 32px;
    flex: 1;

    max-width: 800px;
  }
`;

const FullScreen = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  @media (max-width: ${MOBILE_BREAKPOINT_WIDTH - 1}px) {
    padding: 12px;
  }
  @media (min-width: ${MOBILE_BREAKPOINT_WIDTH}px) {
    padding: 32px;
  }
`;
