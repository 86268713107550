import "src/verse/src/theme/global.css";
import { VerseThemeProvider } from "src/verse/src";
import "src/utils/i18n";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { VirtualBgGenerator } from "./view";

const Routing = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <VirtualBgGenerator />
        </Route>

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
};

export const App = () => {
  return (
    <VerseThemeProvider>
      <Routing />
    </VerseThemeProvider>
  );
};
