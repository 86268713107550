import { VerseBody2, VerseBox, VerseSubtitle3 } from "src/verse/src";
import { StyledRangeInput } from "src/components/FormElements/StyledRangeInput";
import { useVirtualBgContext } from "src/context/VirtualBgContext";
export function LogoDegreeInput() {
  const { logoDegree, logoDegreeChange } = useVirtualBgContext();
  return (
    <VerseBox>
      <VerseBody2 mb={1}>Rotate</VerseBody2>
      <StyledRangeInput
        value={logoDegree}
        onChange={(evt) => logoDegreeChange?.(Number(evt.target.value))}
        min="0"
        max="360"
      />
    </VerseBox>
  );
}
