export const PointySquiggle = () => {
  return (
    <svg
      width="484"
      height="487"
      viewBox="0 0 484 487"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M334.044 133.691C291.306 132.282 257.286 150.03 249.272 175.885C242.803 196.78 251.922 227.48 266.897 230.726C277.348 232.951 291.827 222.019 291.325 213.303C290.701 201.917 264.378 192.726 243.11 192.552C220.681 192.329 190.024 202.013 190.003 216.639C190.024 228.386 204.177 242.926 216.766 240.303C226.156 238.357 233.255 227.121 232.434 217.71C230.797 199.038 198.197 190.351 194.518 189.396C151.631 178.47 98.3657 205.605 97.9484 229.155C97.7106 242.114 113.025 254.823 118.233 257.585C136.744 267.491 147.853 266.64 157.237 279.947C158.543 281.815 165.068 291.466 162.599 303.001C160.129 314.536 149.329 326.326 138.756 324.875C129.946 323.671 121.707 313.249 121.72 303.718C121.753 280.415 171.077 246.574 210.58 260.994C239.68 271.662 251.713 292 251.713 309.069C251.713 328.5 249.917 371.7 232.434 388.5"
        stroke="#0A0B1E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M188.882 389.079L195.817 393.146"
        stroke="#0A0B1E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M241.283 415.78L248.77 418.712"
        stroke="#0A0B1E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M207.359 372.208L210.58 379.575"
        stroke="#0A0B1E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M205.071 434.226L208.003 426.74"
        stroke="#0A0B1E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M187.297 414.838L195.111 412.946"
        stroke="#0A0B1E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M228.747 435.719L226.855 427.905"
        stroke="#0A0B1E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M218.898 402.003C218.667 402.755 219.422 403.434 220.146 403.124L249.689 390.464C250.519 390.108 250.417 388.822 249.552 388.561C246.351 387.596 241.122 385.652 237.579 382.468C234.036 379.284 231.545 374.292 230.244 371.212C229.893 370.38 228.604 370.415 228.339 371.279L218.898 402.003Z"
        fill="#0A0B1E"
        stroke="#0A0B1E"
        strokeWidth="2"
      />
    </svg>
  );
};
