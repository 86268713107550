import React from 'react';

import { VerseButtonSizeEnum } from './consts/buttonConsts';
import {
  VerseButtonTextXSmall,
  VerseButtonTextSmall,
  VerseButtonTextMedium,
  VerseButtonTextLarge,
  VerseButtonTextXLarge,
} from '../VerseTypography/Internal';
import { VerseButtonTextProps } from './typings';
import { VerseTypographyComponentProps } from '..';

export const ButtonText = ({
  title,
  buttonSize = VerseButtonSizeEnum.XSMALL,
  html,
}: VerseButtonTextProps) => {
  const commonProps: VerseTypographyComponentProps = {
    linkify: false,
    t: title,
    html,
    component: 'span',
  };

  switch (buttonSize) {
    case VerseButtonSizeEnum.XSMALL:
      return <VerseButtonTextXSmall {...commonProps} />;
    case VerseButtonSizeEnum.SMALL:
      return <VerseButtonTextSmall {...commonProps} />;
    case VerseButtonSizeEnum.MEDIUM:
      return <VerseButtonTextMedium {...commonProps} />;
    case VerseButtonSizeEnum.LARGE:
      return <VerseButtonTextLarge {...commonProps} />;
    case VerseButtonSizeEnum.XLARGE:
      return <VerseButtonTextXLarge {...commonProps} />;
    default:
      return null;
  }
};
