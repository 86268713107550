import {
  VerseButton,
  VerseButtonSizeEnum,
  VerseButtonVariantEnum,
  VerseIconIdEnum,
} from "src/verse/src";
import { useVirtualBgContext } from "src/context/VirtualBgContext";

import styled from "styled-components";
import { useRef } from "react";
const StyledInput = styled.input`
  visibility: hidden;
`;
export function LogoSelector() {
  const { logo, logoChange, logoScaleChange } = useVirtualBgContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    inputRef?.current?.click();
  };

  return (
    <>
      {logo ? (
        <VerseButton
          title="Change Logo"
          testId="change-logo"
          width="100%"
          size={VerseButtonSizeEnum.MEDIUM}
          variant={VerseButtonVariantEnum.SECONDARY_CTA}
          onClick={() => {
            logoChange?.(null);
          }}
        />
      ) : (
        <>
          <VerseButton
            title="Upload"
            testId="upload-logo"
            width="100%"
            icon={{
              iconId: VerseIconIdEnum.UPLOAD,
            }}
            size={VerseButtonSizeEnum.MEDIUM}
            variant={VerseButtonVariantEnum.SECONDARY_CTA}
            onClick={handleSelectFile}
          />
          <StyledInput
            ref={inputRef}
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            onChange={(e) => {
              if (!e.target.files || e.target.files.length === 0) {
                return;
              }
              const objectUrl = URL.createObjectURL(e.target.files[0]);
              logoChange?.(objectUrl);
              logoScaleChange?.(1);
            }}
          />
        </>
      )}
    </>
  );
}
