import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ExpandIcon = ({
  fill = 'transparent',
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.37514 19.3113C1.58283 21.0843 2.91541 22.4169 4.68847 22.6246C6.34899 22.8191 8.76327 23 11.9998 23C15.2362 23 17.6505 22.8191 19.311 22.6246C21.0841 22.4169 22.4167 21.0843 22.6244 19.3113C22.8189 17.6508 22.9998 15.2365 22.9998 12C22.9998 8.76351 22.8189 6.34923 22.6244 4.68872C22.4167 2.91566 21.0841 1.58307 19.311 1.37539C17.6505 1.18088 15.2362 1 11.9998 1C8.76327 1 6.34899 1.18088 4.68847 1.37539C2.91541 1.58307 1.58283 2.91566 1.37514 4.68872C1.18064 6.34923 0.999756 8.76351 0.999756 12C0.999756 15.2365 1.18064 17.6508 1.37514 19.3113Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      fill={Array.isArray(fill) ? fill[0] : fill}
      strokeWidth={strokeWidth}
    />
    <path
      d="M16.5 22.5L16.5 1.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7.19995 7.5L11.7 12L7.19995 16.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={Array.isArray(fill) ? fill[0] : fill}
      strokeWidth={strokeWidth}
    />
  </>
);
