import React, { forwardRef } from "react";
import styled from "styled-components";

import { VerseIcon } from "..";
import { VerseLabelText } from "../VerseTypography/Internal/Label/VerseLabelText";
import { VerseLabelVariantEnum } from "./consts/labelConsts";
import { VerseLabelComponentProps, VerseLabelContainerProps } from "./typings";

const ONLY_LOWER_CASE_REGEX = /^[^A-Z][^0-9]*$/g;
const ONLY_UPPER_CASE_REGEX = /^[^a-z]*$/g;

export const VerseLabel = forwardRef<HTMLDivElement, VerseLabelComponentProps>(
  (
    {
      variant = VerseLabelVariantEnum.PRIMARY_DARK_10,
      text,
      icon,
      testId,
      shouldHaveDefaultTitleProp = true,
      ...other
    }: VerseLabelComponentProps,
    ref
  ) => {
    /** move text down by `0.25px` if text doesn't contains any lowercase letters */
    const onlyLowercase = text
      ? (text?.match(ONLY_LOWER_CASE_REGEX) ?? []).length > 0
      : false;
    /** move text up by `0.5px` if text contains only lowercase letters */
    const onlyUppercase = text
      ? (text?.match(ONLY_UPPER_CASE_REGEX) ?? []).length > 0
      : false;

    return (
      <LabelContainer
        variant={variant}
        data-testid={testId}
        onlyLowercase={onlyLowercase}
        onlyUppercase={onlyUppercase}
        ref={ref}
        {...other}
      >
        {icon ? (
          <VerseIcon size={12} fill="transparent" {...icon} mr={0.5} />
        ) : null}
        <VerseLabelText
          color="inherit"
          component="span"
          linkify={false}
          title={shouldHaveDefaultTitleProp ? text : undefined}
        >
          {text}
        </VerseLabelText>
      </LabelContainer>
    );
  }
);

interface LabelContainerProps extends Omit<VerseLabelContainerProps, "text"> {
  onlyLowercase?: boolean;
  onlyUppercase?: boolean;
}

const LabelContainer = styled.div<LabelContainerProps>`
  position: relative;
  height: 20px;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  border-radius: 4px;
  overflow: hidden;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  transition: ${({ theme }) => theme.animationSpeed}ms all linear;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  ${({ width }) =>
    width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}

  ${({ variant, onClick, theme }) => {
    let bgColor;
    switch (variant) {
      case VerseLabelVariantEnum.PRIMARY_75:
        bgColor = theme.colors.main.PrimaryYellow75;
        break;
      case VerseLabelVariantEnum.BLUE_25:
        bgColor = theme.colors.other.Blue25;
        break;
      case VerseLabelVariantEnum.GREEN_50:
        bgColor = theme.colors.other.Green50;
        break;
      case VerseLabelVariantEnum.DANGER_25:
        bgColor = theme.colors.other.Danger25;
        break;
      case VerseLabelVariantEnum.BLUE_50:
        bgColor = theme.colors.other.Blue50;
        break;
      case VerseLabelVariantEnum.WHITE:
        bgColor = theme.colors.other.White;
        break;

      default:
        bgColor = theme.colors.main.PrimaryDark20;
    }
    let cssString = `background-color: ${bgColor};`;
    if (onClick) {
      cssString += `cursor: pointer;

      ::after {
        transition: all ${theme.animationSpeed}ms linear;
        opacity: 0;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        background: rgba(0,0,0,0.1);
      }
      
      :hover { 
        ::after {
          opacity: 1;
        }
      }`;
    }

    return cssString;
  }}

  ${({ bgUrl, theme }) =>
    bgUrl
      ? `
      background: center / cover no-repeat url('${bgUrl}');
      span { 
        color: ${theme.colors.other.White} !important;
        opacity: 1 !important;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55) !important;
      }
    `
      : ""}

  ${({ onlyLowercase, onlyUppercase }) => {
    if (onlyLowercase) {
      return `span { transform: translateY(-0.5px); }`;
    }
    if (onlyUppercase) {
      return `span { transform: translateY(0.5px); }`;
    }
    return "";
  }}
`;
