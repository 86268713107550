import {
  VerseBox,
  VerseButton,
  VerseButtonSizeEnum,
  VerseButtonVariantEnum,
} from "src/verse/src";
import styled from "styled-components";

import { MOBILE_BREAKPOINT_WIDTH } from "src/consts/layout";
import { ButterLogoLong } from "src/components/ButterLogoLong";
import {
  BUTTER_BLOG_LINK,
  BUTTER_COMMUNITY_LINK,
  BUTTER_DOWNLOAD_LINK,
  BUTTER_FEAUTRES_LINK,
  BUTTER_PRICING_LINK,
  BUTTER_SIGN_IN_LINK,
  BUTTER_SIGN_UP_LINK,
  BUTTER_TEMPLATES_LINK,
  BUTTER_URL,
} from "src/consts";

export function VirtualBgGeneratorHeader() {
  return (
    <>
      <StyledHeader>
        <StyledHeaderContainer>
          <VerseBox px={1.5} py={0.5}>
            <a href={BUTTER_URL} target="_blank" rel="noopener noreferrer">
              <ButterLogoLong size={104} />
            </a>
          </VerseBox>
          <VerseBox display="flex" alignItems="center" flex={1}>
            <a
              href={BUTTER_FEAUTRES_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VerseButton
                size={VerseButtonSizeEnum.SMALL}
                title="Features"
                testId="features"
                mr={1}
                variant={VerseButtonVariantEnum.TEXT_ICON_LINK}
              />
            </a>
            <HideBreakpoint breakpoint={MOBILE_BREAKPOINT_WIDTH}>
              <>
                <a
                  href={BUTTER_DOWNLOAD_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VerseButton
                    size={VerseButtonSizeEnum.SMALL}
                    title="Download"
                    testId="download"
                    mr={1}
                    variant={VerseButtonVariantEnum.TEXT_ICON_LINK}
                  />
                </a>

                <a
                  href={BUTTER_TEMPLATES_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VerseButton
                    size={VerseButtonSizeEnum.SMALL}
                    title="Templates"
                    testId="templates"
                    mr={1}
                    variant={VerseButtonVariantEnum.TEXT_ICON_LINK}
                  />
                </a>
                <a
                  href={BUTTER_PRICING_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VerseButton
                    size={VerseButtonSizeEnum.SMALL}
                    title="Pricing"
                    testId="pricing"
                    mr={1}
                    variant={VerseButtonVariantEnum.TEXT_ICON_LINK}
                  />
                </a>
              </>
            </HideBreakpoint>
            <HideBreakpoint breakpoint={785}>
              <a
                href={BUTTER_BLOG_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <VerseButton
                  size={VerseButtonSizeEnum.SMALL}
                  title="Blog"
                  testId="blog"
                  mr={1}
                  variant={VerseButtonVariantEnum.TEXT_ICON_LINK}
                />
              </a>
            </HideBreakpoint>
            <HideBreakpoint breakpoint={900}>
              <a
                href={BUTTER_COMMUNITY_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <VerseButton
                  size={VerseButtonSizeEnum.SMALL}
                  title="Community"
                  testId="community"
                  mr={1}
                  variant={VerseButtonVariantEnum.TEXT_ICON_LINK}
                />
              </a>
            </HideBreakpoint>
          </VerseBox>
          <VerseBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flex={1}
          >
            <a
              href={BUTTER_SIGN_IN_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VerseButton
                size={VerseButtonSizeEnum.SMALL}
                title="Sign in"
                testId="sign-in"
                mr={1}
                variant={VerseButtonVariantEnum.TEXT_ICON_LINK}
              />
            </a>
            <a
              href={BUTTER_SIGN_UP_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VerseButton
                size={VerseButtonSizeEnum.SMALL}
                title="Try Butter for free"
                testId="try-butter-for-free"
              />
            </a>
          </VerseBox>
        </StyledHeaderContainer>
      </StyledHeader>
    </>
  );
}
const StyledHeaderContainer = styled.div`
  padding: 12px;
  box-shadow: ${({ theme }) => theme.shadows.cardShadowNoBorder};
  display: flex;
  width: 100%;

  border-radius: 20px;
`;

const StyledHeader = styled.div`
  padding: 0 12px;

  max-width: 1304px;
  margin: 24px auto;
`;
const HideBreakpoint = styled.div<{ breakpoint: number }>`
  @media (max-width: ${({ breakpoint }) => breakpoint - 1}px) {
    display: none;
  }
  @media (min-width: ${({ breakpoint }) => breakpoint}px) {
    display: flex;
  }
`;
