export enum PositionEnum {
  TOP_LEFT = "topLeft",
  TOP_RIGHT = "topRight",
  BOTTOM_LEFT = "bottomLeft",
  BOTTOM_RIGHT = "bottomRight",
  CENTER = "center",
}
export const POSITION_PARAMS: Record<
  PositionEnum,
  {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
    transformOrigin: string;
  }
> = {
  [PositionEnum.TOP_LEFT]: {
    top: "5%",
    left: "5%",
    transformOrigin: "top left",
  },
  [PositionEnum.TOP_RIGHT]: {
    top: "5%",
    right: "5%",
    transformOrigin: "top right",
  },
  [PositionEnum.BOTTOM_LEFT]: {
    bottom: "5%",
    left: "5%",
    transformOrigin: "bottom left",
  },
  [PositionEnum.BOTTOM_RIGHT]: {
    bottom: "5%",
    right: "5%",
    transformOrigin: "bottom right",
  },
  [PositionEnum.CENTER]: {
    top: "50%",
    left: "50%",
    transformOrigin: "left",
  },
};

export const positionOptions = [
  {
    label: "Top Left",
    value: PositionEnum.TOP_LEFT,
  },
  {
    label: "Top Right",
    value: PositionEnum.TOP_RIGHT,
  },
  {
    label: "Bottom Left",
    value: PositionEnum.BOTTOM_LEFT,
  },
  {
    label: "Bottom Right",
    value: PositionEnum.BOTTOM_RIGHT,
  },
  {
    label: "Center",
    value: PositionEnum.CENTER,
  },
];
