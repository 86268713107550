import { useEffect, useState } from 'react';
import Mousetrap from 'mousetrap';

interface UseKeyPressHookParams {
  ref?: React.RefObject<HTMLDivElement> | null;
  onKeyDown?: (e?: any) => void;
  onKeyUp?: (e?: any) => void;
  targetKey: string;
  enabled: boolean;
  disableStopCallback?: boolean;
}

/**
 * useKeyPress hook
 * ---
 * @description - hook that uses mousetrap js to bind hotkeys
 * @see reference - https://craig.is/killing/mice for supported keys
 *
 */
export function useKeyPress({
  ref,
  onKeyDown,
  onKeyUp,
  targetKey,
  enabled = false,
  disableStopCallback = false,
}: UseKeyPressHookParams) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler(e?: any) {
    setKeyPressed(true);
    onKeyDown && onKeyDown(e);
  }

  const upHandler = (e?: any) => {
    setKeyPressed(false);
    onKeyUp && onKeyUp(e);
  };

  const currentRef = ref?.current;

  // Add event listeners
  useEffect(() => {
    if (enabled) {
      const lowercaseKey = targetKey.toLowerCase();
      let mousetrap: Mousetrap.MousetrapInstance;
      if (currentRef) {
        mousetrap = new Mousetrap(currentRef);
      }
      mousetrap = new Mousetrap();

      // override default stopCallback if disableStopCallback is set to true
      if (disableStopCallback) {
        mousetrap.stopCallback = function () {
          return false;
        };
      }

      mousetrap.bind(lowercaseKey, downHandler, 'keydown');
      mousetrap.bind(lowercaseKey, upHandler, 'keyup');
      return () => {
        mousetrap.unbind(lowercaseKey, 'keydown');
        mousetrap.unbind(lowercaseKey, 'keyup');
      };
    }
  }, [enabled, currentRef, downHandler, upHandler]);

  return keyPressed;
}

function areKeysPressed(keys: string[] = [], keysPressed: Set<string> = new Set([])) {
  const required = new Set(keys);
  for (const elem of keysPressed) {
    required.delete(elem);
  }
  return required.size === 0;
}

export default function useMultiKeyPress({
  ref,
  onMatch,
  targetKeys,
  enabled = false,
}: {
  ref?: React.RefObject<HTMLDivElement> | null;

  onMatch?: () => void;
  targetKeys: string[];
  enabled: boolean;
}) {
  const [keysPressed, setKeyPressed] = useState<Set<string>>(new Set([]));

  function downHandler({ key }: { key: string}) {
    setKeyPressed(keysPressed.add(key));
  }

  const upHandler = ({ key }: { key: string}) => {
    keysPressed.delete(key);
    setKeyPressed(keysPressed);
  };

  const currentRef = ref?.current;

  useEffect(() => {
    if (enabled) {
      if (currentRef) {
        currentRef.addEventListener('keydown', downHandler);
        currentRef.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
          currentRef.removeEventListener('keydown', downHandler);
          currentRef.removeEventListener('keyup', upHandler);
        };
      }
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
      };
    }
  }, [enabled, currentRef, downHandler, upHandler]);

  const matchKeys = areKeysPressed(targetKeys, keysPressed);

  useEffect(() => {
    if (matchKeys) {
      onMatch && onMatch();
    }
  }, [matchKeys]);

  return keysPressed;
}
