import React from "react";
import {
  VerseButton,
  VerseButtonSizeEnum,
  VerseIconIdEnum,
} from "src/verse/src";
import domtoimage from "dom-to-image";

export function SaveAsPNG() {
  const [loading, setLoading] = React.useState(false);

  const handleSave = () => {
    setLoading(true);
    if (typeof window !== "undefined") {
      try {
        (window as any)?.gtag?.("event", "click", {
          event_category: "virtual-background",
          event_label: "save-as-png",
        });
      } catch (err) {
        console.error(err);
      }
    }
    domtoimage
      .toPng(document.getElementById("export"))
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "custom-virtual-bg-by-Butter.png";
        link.href = dataUrl;
        link.click();
        setLoading(false);
      });
  };

  return (
    <VerseButton
      testId="export"
      title="Save as PNG"
      icon={{
        iconId: VerseIconIdEnum.DOWNLOAD,
      }}
      onClick={handleSave}
      size={VerseButtonSizeEnum.LARGE}
      loading={loading}
      width="100%"
    />
  );
}
