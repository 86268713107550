import { VerseBox, VerseH4 } from "src/verse/src";

import { LogoSelector } from "src/components/FormElements/LogoSelector";
import { LogoScaleInput } from "src/components/FormElements/LogoScaleInput";
import { LogoXInput } from "src/components/FormElements/LogoXInput";
import { LogoYInput } from "src/components/FormElements/LogoYInput";
import { LogoDegreeInput } from "src/components/FormElements/LogoDegreeInput";
import { LogoPositionSelector } from "src/components/FormElements/LogoPositionSelector";
import { TextPositionSelector } from "src/components/FormElements/TextPositionSelector";
import { TextInput } from "src/components/FormElements/TextInput";
import { TextScaleInput } from "src/components/FormElements/TextScaleInput";
import { TextXInput } from "src/components/FormElements/TextXInput";
import { TextYInput } from "src/components/FormElements/TextYInput";
import { TextDegreeInput } from "src/components/FormElements/TextDegreeInput";
import { TextColorInput } from "src/components/FormElements/TextColorInput";
import { GradientHexInput } from "src/components/FormElements/GradientHexInput";
import { GradientHex2Input } from "src/components/FormElements/GradientHex2Input";
import { GradientDegreeInput } from "src/components/FormElements/GradientDegreeInput";

import { SaveAsPNG } from "src/components/SaveAsPNG";
import styled from "styled-components";
import { AspectRatioSelector } from "src/components/FormElements/AspectRatioSelector";
import { BackgroundTypeSelector } from "src/components/FormElements/BackgroundTypeSelector";
import { useVirtualBgContext } from "src/context/VirtualBgContext";
import { BackgroundTypeEnum } from "src/consts/background";
import { BackgroundImageSelector } from "src/components/FormElements/BackgroundImageSelector";
import { MAX_HEIGHT_SIDEBAR, MOBILE_BREAKPOINT_WIDTH } from "src/consts/layout";
import { PointySquiggle } from "src/components/Icons/PointySquiggle";
export function VirtualBgGeneratorSidebar() {
  const { backgroundType } = useVirtualBgContext();

  const renderBackgroundInputs = () => {
    switch (backgroundType) {
      case BackgroundTypeEnum.GRADIENT: {
        return (
          <>
            <VerseBox mb={1}>
              <GradientHexInput />
            </VerseBox>
            <VerseBox mb={2}>
              <GradientHex2Input />
            </VerseBox>
            <GradientDegreeInput />
          </>
        );
      }
      case BackgroundTypeEnum.SOLID: {
        return (
          <>
            <GradientHexInput />
          </>
        );
      }
      case BackgroundTypeEnum.IMAGE: {
        return (
          <>
            <BackgroundImageSelector />
          </>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <VerseBox position="relative">
      <StyledSidebarContainer>
        <StyledSidebarComponent>
          <VerseBox mb={4}>
            <VerseBox mb={2} width="100%" maxWidth={1080}>
              <VerseH4>General</VerseH4>
            </VerseBox>

            <AspectRatioSelector />
          </VerseBox>
          <VerseBox mb={4}>
            <VerseBox mb={2} width="100%" maxWidth={1080}>
              <VerseH4>Background</VerseH4>
            </VerseBox>
            <VerseBox mb={1}>
              <BackgroundTypeSelector />
            </VerseBox>

            {renderBackgroundInputs()}
          </VerseBox>
          <VerseBox mb={4}>
            <VerseBox mb={2} width="100%" maxWidth={1080}>
              <VerseH4>Logo</VerseH4>
            </VerseBox>

            <VerseBox mb={2}>
              <LogoSelector />
            </VerseBox>
            <VerseBox mb={2}>
              <LogoPositionSelector />
            </VerseBox>
            <VerseBox mb={2}>
              <LogoScaleInput />
            </VerseBox>
            <VerseBox mb={2}>
              <LogoXInput />
            </VerseBox>
            <VerseBox mb={2}>
              <LogoYInput />
            </VerseBox>
            <VerseBox mb={2}>
              <LogoDegreeInput />
            </VerseBox>
          </VerseBox>
          <VerseBox>
            <VerseBox mb={2} width="100%" maxWidth={1080}>
              <VerseH4>Text </VerseH4>
            </VerseBox>
            <VerseBox mb={2}>
              <TextInput />
            </VerseBox>
            <VerseBox mb={2}>
              <TextPositionSelector />
            </VerseBox>
            <VerseBox mb={2}>
              <TextColorInput />
            </VerseBox>
            <VerseBox mb={2}>
              <TextScaleInput />
            </VerseBox>
            <VerseBox mb={2}>
              <TextXInput />
            </VerseBox>
            <VerseBox mb={2}>
              <TextYInput />
            </VerseBox>
            <VerseBox mb={2}>
              <TextDegreeInput />
            </VerseBox>
          </VerseBox>
        </StyledSidebarComponent>

        <StyledSidebarFooter>
          <SaveAsPNG />
        </StyledSidebarFooter>
      </StyledSidebarContainer>
      <StyledSquiggle>
        <PointySquiggle />
      </StyledSquiggle>
    </VerseBox>
  );
}
const StyledSquiggle = styled.div`
  position: absolute;

  pointer-events: none;
  z-index: 3;
  @media (min-width: ${MOBILE_BREAKPOINT_WIDTH}px) {
    right: 124px;
    top: 430px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT_WIDTH - 1}px) {
    bottom: -310px;
    transform: scale(0.55) translateX(-50%);
    left: 50%;
    transform-origin: left;
  }
`;

const StyledSidebarComponent = styled.div`
  width: 100%;
  overflow: auto;
  padding: 32px 24px;

  padding-bottom: 140px;

  ${({ theme }) => theme.helpers.verseScrollbar}
  @media (min-width: ${MOBILE_BREAKPOINT_WIDTH}px) {
    height: 100%;
    max-height: ${MAX_HEIGHT_SIDEBAR}px;
  }
`;
const StyledSidebarContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.cardShadowWithBorder};
  border-radius: 12px;
  position: relative;

  @media (max-width: ${MOBILE_BREAKPOINT_WIDTH - 1}px) {
    width: 100%;
  }
  @media (min-width: ${MOBILE_BREAKPOINT_WIDTH}px) {
    width: 280px;
  }
  overflow: hidden;
`;
const StyledSidebarFooter = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows.cardShadowWithBorder};
  padding: 12px;
  z-index: 2;
  border-radius: 0 0 12px 12px;
`;
