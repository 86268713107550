import React from 'react';

import { useVerseTheme } from '../..';
import { VerseIcon } from '../VerseIcon';
import {
  VerseButtonIconPositionEnum,
  VerseButtonSizeEnum,
  VerseButtonVariantEnum,
} from './consts/buttonConsts';
import { VerseButtonIconProps } from './typings';

export const ButtonIcon = ({
  icon,
  buttonSize,
  buttonVariant,
}: VerseButtonIconProps) => {
  const theme = useVerseTheme();
  const defaultIconStroke = React.useMemo(() => {
    switch (buttonVariant) {
      case VerseButtonVariantEnum.PRIMARY_CTA:
        return theme.colors.other.White;
      case VerseButtonVariantEnum.BRANDED_CTA:
      case VerseButtonVariantEnum.SECONDARY_CTA:
      case VerseButtonVariantEnum.TERTIARY_CTA:
      case VerseButtonVariantEnum.TEXT_ICON_LINK:
      default:
        return theme.colors.main.PrimaryDark100;
    }
  }, [buttonVariant]);

  const iconMarginRight = React.useMemo(() => {
    switch (buttonSize) {
      case VerseButtonSizeEnum.LARGE:
      case VerseButtonSizeEnum.MEDIUM:
        return 0.75;
      case VerseButtonSizeEnum.SMALL:
      case VerseButtonSizeEnum.XSMALL:
      default:
        return 0.5;
    }
  }, [buttonSize]);

  const defaultIconProps = React.useMemo(() => {
    return {
      size: buttonSize === VerseButtonSizeEnum.XSMALL ? 12 : 16,
      fill: 'transparent',
      stroke: defaultIconStroke,
    };
  }, [defaultIconStroke, buttonSize]);

  if (icon) {
    const { position = VerseButtonIconPositionEnum.LEFT, ...other } = icon;

    switch (position) {
      case VerseButtonIconPositionEnum.LEFT:
        return (
          <VerseIcon mr={iconMarginRight} {...defaultIconProps} {...other} />
        );
      case VerseButtonIconPositionEnum.RIGHT:
        return (
          <VerseIcon ml={iconMarginRight} {...defaultIconProps} {...other} />
        );
      default:
        return (
          <VerseIcon mr={iconMarginRight} {...defaultIconProps} {...other} />
        );
    }
  }
  return null;
};
