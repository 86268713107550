import { ThumbsUp } from "src/components/Icons/ThumbsUp";
import { BUTTER_SIGN_UP_LINK } from "src/consts";
import { MAX_HEIGHT_SIDEBAR, MOBILE_BREAKPOINT_WIDTH } from "src/consts/layout";
import {
  VerseBody1,
  VerseButton,
  VerseButtonSizeEnum,
  VerseH1,
  VerseH3,
  VerseSubtitle1,
} from "src/verse/src";
import styled from "styled-components";

export function VirtualBgGeneratorCTA() {
  return (
    <>
      <StyledCTAContainer>
        <StyledTitle>
          Use your custom backgrounds in Butter!{" "}
          <strong>Get started for free!</strong>
        </StyledTitle>
        <StyledButtonPosition>
          <a
            href={BUTTER_SIGN_UP_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <VerseButton
              size={VerseButtonSizeEnum.XLARGE}
              width={630}
              height={107}
              title=""
              testId="try-butter-for-free"
            />
          </a>
        </StyledButtonPosition>

        <StyledButtonTextPosition>
          <StyledTextButton color="#fff">Try Butter for free</StyledTextButton>
        </StyledButtonTextPosition>
      </StyledCTAContainer>
      <StyledCTAContainerMobile>
        <StyledTitle2>
          Use your custom backgrounds in Butter!{" "}
          <strong>Get started for free!</strong>
        </StyledTitle2>

        <StyledButtonPosition>
          <a
            href={BUTTER_SIGN_UP_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <VerseButton
              size={VerseButtonSizeEnum.XLARGE}
              title="Try Butter for free"
              testId="try-butter-for-free"
            />
          </a>
        </StyledButtonPosition>
      </StyledCTAContainerMobile>

      <StyledThumbsUpPosition>
        <ThumbsUp />
      </StyledThumbsUpPosition>
    </>
  );
}
const StyledCTAContainer = styled.div`
  width: 100%;
  height: 596px;
  background: ${({ theme }) => theme.colors.main.PrimaryYellow100};
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: ${MOBILE_BREAKPOINT_WIDTH - 1}px) {
    display: none;
  }
  @media (min-width: ${MOBILE_BREAKPOINT_WIDTH}px) {
    display: flex;
  }
`;
const StyledCTAContainerMobile = styled.div`
  width: 100%;
  height: 320px;
  background: ${({ theme }) => theme.colors.main.PrimaryYellow100};
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: ${MOBILE_BREAKPOINT_WIDTH - 1}px) {
    display: flex;
  }
  @media (min-width: ${MOBILE_BREAKPOINT_WIDTH}px) {
    display: none;
  }
  padding: 16px;
`;
const StyledThumbsUpPosition = styled.div`
  position: absolute;
  @media (min-width: ${MOBILE_BREAKPOINT_WIDTH}px) {
    left: -72px;

    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 1000px) {
    left: -32px;

    top: 50%;
    transform: translateY(-50%) scale(0.5);

    transform-origin: left;
  }
  @media (max-width: 877px) {
    left: -32px;

    top: 50%;
    transform: translateY(-50%) scale(0.3);

    transform-origin: left;
  }
  @media (max-width: ${MOBILE_BREAKPOINT_WIDTH - 1}px) {
    transform: scale(0.3);
    left: -12px;
    bottom: 124px;
    transform-origin: bottom left;
  }
`;
const StyledButtonPosition = styled.div`
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
`;
const StyledButtonTextPosition = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 32px !important;
  pointer-events: none;
`;
const StyledTextButton = styled(VerseBody1)`
  font-size: 32px !important;
  line-height: 1 !important;
  text-align: center;
`;
const StyledTitle = styled(VerseBody1)`
  font-size: 64px !important;
  line-height: 1 !important;
  width: 750px;
  text-align: center;
`;
const StyledTitle2 = styled(VerseBody1)`
  font-size: 32px !important;
  line-height: 1 !important;
  width: 750px;
  text-align: center;
`;
