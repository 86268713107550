import React from 'react';
import styled from 'styled-components';

import {
  VerseBody3,
  VerseDelayState,
  VerseDelayUnmount,
  VerseDelayUnmountEnum,
} from '..';
import { VerseKeyframes } from '../VerseKeyframes';
import { VerseBadgeComponentProps, VerseBadgeContainerProps } from './typings';

export function VerseBadge({
  invisible,
  children,
  offsetX,
  offsetY,
  content,
  max,
  textColor,
  width,
  align,
  bgColor,
  ...props
}: VerseBadgeComponentProps) {
  const isNumberContent = typeof content === 'number';
  const renderNumberContent = (num: number) => {
    return max ? Math.min(num, max) : num;
  };

  // finds onClick property from children
  const neighbourClickHandlers = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      if (child.props.onClick) {
        return child.props.onClick;
      }
    }
  });

  const bubbleClick = neighbourClickHandlers?.[0];

  return (
    <OuterContainer width={width}>
      {children}

      <VerseDelayUnmount isMounted={!invisible}>
        {({ state }) => (
          <Badge
            state={state}
            align={align}
            bgColor={bgColor}
            offsetX={offsetX}
            offsetY={offsetY}
            {...props}
            onClick={bubbleClick ?? null}
          >
            <BadgeText textColor={textColor}>
              {isNumberContent ? renderNumberContent(content) : content}
            </BadgeText>
          </Badge>
        )}
      </VerseDelayUnmount>
    </OuterContainer>
  );
}
const OuterContainer = styled.div<{ width?: string | number }>`
  position: relative;

  width: ${({ width }) =>
    width ? (typeof width === 'string' ? width : `${width}px`) : 'fit-content'};
`;

const fadeInAnimation = VerseKeyframes.fadeInKeyframes();
const fadeOutAnimation = VerseKeyframes.fadeOutKeyframes();
interface BadgeProps extends VerseBadgeContainerProps {
  state: VerseDelayState;
}
const Badge = styled.div<BadgeProps>`
  position: absolute;

  ${({ size = 22, offsetY = 0, offsetX = 0, align = 'right' }) => `
    width: auto;
    min-width: ${size}px;
    padding: 0 6px;
    height: ${size}px;
    border-radius: 32px;
    top: ${-(size / 2) + offsetY}px;
    ${align}: ${-(size / 2) + offsetX}px;
  `}

  animation: ${({ state }) => {
    switch (state) {
      case VerseDelayUnmountEnum.MOUNTED:
      case VerseDelayUnmountEnum.MOUNTING: {
        return fadeInAnimation;
      }
      default: {
        return fadeOutAnimation;
      }
    }
  }}
  ${({ theme }) => theme.animationSpeed}ms 1;

  background-color: ${({ bgColor, theme }) =>
    bgColor ?? theme.colors.other.Danger100};

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const BadgeText = styled(VerseBody3)<{ textColor?: string }>`
  && {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: ${({ textColor, theme }) => textColor ?? theme.colors.other.White};
  }
`;
