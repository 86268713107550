import {
  VerseBody2,
  VerseBox,
  VerseSelect,
  VerseSelectOptionObj,
  VerseSelectSizeEnum,
  VerseSubtitle3,
} from "src/verse/src";

import { useVirtualBgContext } from "src/context/VirtualBgContext";
import { PositionEnum, positionOptions } from "src/consts/position";
import { useForm } from "react-hook-form";
export function TextPositionSelector() {
  const { textPosition, textPositionChange } = useVirtualBgContext();
  const { control } = useForm();

  const handleOnChangeLogo = (value: VerseSelectOptionObj) => {
    const resultLayoutToUse = value.value as PositionEnum;
    textPositionChange?.(resultLayoutToUse);
  };
  return (
    <VerseBox>
      <VerseBody2 mb={1}>Text position</VerseBody2>
      <VerseSelect
        options={positionOptions}
        onChange={handleOnChangeLogo}
        value={positionOptions.find((x) => x.value === textPosition)}
        name="textPosition"
        testId="poll-resultLayoutSelect"
        control={control}
        width="100%"
        size={VerseSelectSizeEnum.MEDIUM}
      />
    </VerseBox>
  );
}
