import React from 'react';

import { VerseTypographyFontWeightEnum } from '../consts';
import {
  VerseTypographyComponentProps,
  VerseTypographyVariantProps,
} from '../typings';
import { WrappedVerseTypography } from '../WrappedVerseTypography';

export const Body2 = (props: VerseTypographyComponentProps) => {
  const variantProps: VerseTypographyVariantProps = {
    fontSize: 15,
    lineHeight: 1.25,
    fontWeight: VerseTypographyFontWeightEnum.LIGHT,
  };
  return <WrappedVerseTypography variantProps={variantProps} {...props} />;
};
