/* eslint-disable no-unused-vars */
import * as VerseIconInner from '../icons';

export enum VerseIconIdEnum {
  BASE = 'base',
  BASE_ALL = 'baseAll',
  NOTE = 'note',
  BREAKOUT = 'breakout',
  FOLDER = 'folder',
  SHARE_SCREEN = 'shareScreen',
  HOME_V1 = 'homeV1',
  HOME_V2 = 'homeV2',
  CHAT = 'chat',
  CLOCK = 'clock',
  EXIT = 'exit',
  FUNNEL = 'funnel',
  BANNER = 'banner',
  PAPER = 'paper',
  IMAGE = 'image',
  SEARCH = 'search',
  REFRESH = 'refresh',
  RESET = 'reset',
  CALENDAR = 'calendar',
  TRASH = 'trash',
  LIGHTNING = 'lightning',
  COMMAND = 'command',
  MIC_ON = 'micOn',
  MIC_OFF = 'micOff',
  SOUND_ON = 'soundOn',
  SOUND_OFF = 'soundOff',
  VIDEO_ON = 'videoOn',
  VIDEO_OFF = 'videoOff',
  HEART = 'heart',
  CHECK_V1 = 'checkV1',
  CHECK_V2 = 'checkV2',
  PASTE = 'paste',
  PENCIL = 'pencil',
  PIN = 'pin',
  PIN_OFF = 'pinOff',
  PLACEHOLDER = 'placeholder',
  PLAY_PREVIOUS = 'playPrevious',
  PLAY_NEXT = 'playNext',
  PLUS = 'plus',
  POINTER = 'pointer',
  MINUS = 'minus',
  BULB = 'bulb',
  CLIP = 'clip',
  CLIPBOARD = 'clipboard',
  OPEN = 'open',
  ANNOTATE = 'annotate',
  ARROW_DOWN_V1 = 'arrowDownV1',
  ARROW_DOWN_V2 = 'arrowDownV2',
  ARROW_LEFT_V1 = 'arrowLeftV1',
  ARROW_LEFT_V2 = 'arrowLeftV2',
  ARROW_RIGHT_V1 = 'arrowRightV1',
  ARROW_RIGHT_V2 = 'arrowRightV2',
  ARROW_UP_V1 = 'arrowUpV1',
  ARROW_UP_V2 = 'arrowUpV2',
  BACK = 'back',
  BAN = 'ban',
  MEGAPHONE = 'megaphone',
  BOX = 'box',
  CAMERA = 'camera',
  CHEVRON_DOWN = 'chevronDown',
  CHEVRON_LEFT = 'chevronLeft',
  CHEVRON_RIGHT = 'chevronRight',
  NAVIGATION_OPEN = 'navigationOpen',
  NAVIGATION_CLOSE = 'navigationClose',
  CHEVRON_UP = 'chevronUp',
  CLOSE = 'close',
  CSV = 'csv',
  DESCRIPTION = 'description',
  DISTRIBUTE = 'distribute',
  DOWNLOAD = 'download',
  EMOJI_ADD_V1 = 'emojiAddV1',
  EMOJI_ADD_V2 = 'emojiAddV2',
  EMOJI_V1 = 'emojiV1',
  EMOJI_V2 = 'emojiV2',
  EYE = 'eye',
  HIDE = 'hide',
  HIGHLIGHT = 'highlight',
  HIGHLIGHT_END = 'highlightEnd',
  FULLSCREEN_V1 = 'fullscreenV1',
  FULLSCREEN_V2 = 'fullscreenV2',
  GIF = 'gif',
  GRID = 'grid',
  HAND_HELP = 'handHelp',
  HAND_UP = 'handUp',
  HASHTAG = 'hashtag',
  HELP = 'help',
  INFO = 'info',
  LINK = 'link',
  LOADER = 'loader',
  LOCK = 'lock',
  MINIMIZE_V1 = 'minimizeV1',
  MINIMIZE_V2 = 'minimizeV2',
  MORE = 'more',
  MP3 = 'mp3',
  MULTIPLE_CHOICE = 'multipleChoice',
  MUSIC = 'music',
  NEXT = 'next',
  NOTIFICATION = 'notification',
  PAUSE_V1 = 'pauseV1',
  PAUSE_V2 = 'pauseV2',
  PDF = 'pdf',
  PLAY_V1 = 'playV1',
  PLAY_V2 = 'playV2',
  POLL = 'poll',
  POPOUT = 'popout',
  QNA = 'qna',
  QUESTION = 'question',
  RECORD_V1 = 'recordV1',
  RECORD_V2 = 'recordV2',
  RECORD_V3 = 'recordV3',
  REPLY_V1 = 'replyV1',
  REPLY_V2 = 'replyV2',
  SAVE = 'save',
  SCREEN = 'screen',
  SEND = 'send',
  SETTINGS = 'settings',
  SPEAKER = 'speaker',
  SPOTLIGHT = 'spotlight',
  SPOTLIGHT_OFF = 'spotlightOff',
  SPOTLIGHT_VIEW = 'spotlightView',
  PANEL_VIEW = 'panelView',
  STAGE_MIC = 'stageMic',
  STAR = 'star',
  STAR_OFF = 'starOff',
  STOP_V1 = 'stopV1',
  STOP_V2 = 'stopV2',
  TADAA = 'tadaa',
  TAPE = 'tape',
  THUMBS_UP = 'thumbsUp',
  THUMBS_UP_V2 = 'thumbsUpV2',
  TIMER = 'timer',
  TOOLBOX = 'toolbox',
  UPDATE = 'update',
  UPLOAD = 'upload',
  USER = 'user',
  USER_ADD = 'userAdd',
  USER_PAIR = 'userPair',
  USER_GROUP = 'userGroup',
  WARNING_V1 = 'warningV1',
  WARNING_V2 = 'warningV2',
  WIFI = 'wifi',
  WHITEBOARD = 'whiteboard',
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
  BOLD = 'bold',
  BULLETS = 'bullets',
  H1 = 'h1',
  H2 = 'h2',
  ITALIC = 'italic',
  ORDERED_LIST = 'orderedList',
  STRIKETHROUGH = 'strikethrough',
  UNDERLINE = 'underline',
  INDENT = 'indent',
  TEMPLATE = 'template',
  FLASHCARD = 'flashcard',
  OVAL_SMALL = 'ovalSmall',
  OVAL_BIG = 'ovalBig',
  BUTTER = 'butter',
  TEMPLATE_V2 = 'templateV2',
  BLOCK = 'block',
  TOOL = 'tool',
  ADD_TEMPLATE = 'addTemplate',
  POLL_STANDARD = 'pollStandard',
  POLL_WORD_CLOUD = 'pollWordCloud',
  EXPAND = 'expand',
  COLLAPSE = 'collapse',
}
export enum VerseExternalIconIdEnum {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedIn',
  TWITTER = 'twitter',
  PRODUCT_HUNT = 'productHunt',
  FIGMA = 'figma',
  GIPHY = 'giphy',
  GOOGLE = 'google',
  GOOGLE_CALENDAR = 'googleCalendar',
  GOOGLE_CHROME = 'googleChrome',
  GOOGLE_DOC_V1 = 'googleDocV1',
  GOOGLE_DOC_V2 = 'googleDocV2',
  GOOGLE_DRIVE = 'googleDrive',
  GOOGLE_SHEETS = 'googleSheets',
  GOOGLE_SLIDES = 'googleSlides',
  MIRO = 'miro',
  MURAL = 'mural',
  NOTION = 'notion',
  UNSPLASH = 'unsplash',
  YOUTUBE = 'youtube',
  SOUND_CLOUD = 'soundCloud',
}

export enum VerseEmojiIconIdEnum {
  BASIC_EMOJI = 'basicEmoji',
  SMILE_EMOJI = 'smileEmoji',
  WOOHOO_EMOJI = 'woohooEmoji',
  LOVE_THIS_EMOJI = 'loveThisEmoji',
}

export enum VerseIllustrationIconIdEnum {
  LOVE_THIS = 'loveThisIllustration',
  HANDS = 'handsIllustration',
}

export const VERSE_ICON_LIBRARY = {
  [VerseIconIdEnum.BASE]: VerseIconInner.BaseIcon,
  [VerseIconIdEnum.BASE_ALL]: VerseIconInner.BaseAllIcon,
  [VerseIconIdEnum.NOTE]: VerseIconInner.NoteIcon,
  [VerseIconIdEnum.BREAKOUT]: VerseIconInner.BreakoutIcon,
  [VerseIconIdEnum.FOLDER]: VerseIconInner.FolderIcon,
  [VerseIconIdEnum.FLASHCARD]: VerseIconInner.FlashcardIcon,
  [VerseIconIdEnum.SHARE_SCREEN]: VerseIconInner.ShareScreenIcon,
  [VerseIconIdEnum.HOME_V1]: VerseIconInner.HomeV1Icon,
  [VerseIconIdEnum.HOME_V2]: VerseIconInner.HomeV2Icon,
  [VerseIconIdEnum.CHAT]: VerseIconInner.ChatIcon,
  [VerseIconIdEnum.CLOCK]: VerseIconInner.ClockIcon,
  [VerseIconIdEnum.EXIT]: VerseIconInner.ExitIcon,
  [VerseIconIdEnum.FUNNEL]: VerseIconInner.FunnelIcon,
  [VerseIconIdEnum.BANNER]: VerseIconInner.BannerIcon,
  [VerseIconIdEnum.PAPER]: VerseIconInner.PaperIcon,
  [VerseIconIdEnum.IMAGE]: VerseIconInner.ImageIcon,
  [VerseIconIdEnum.SEARCH]: VerseIconInner.SearchIcon,
  [VerseIconIdEnum.REFRESH]: VerseIconInner.RefreshIcon,
  [VerseIconIdEnum.RESET]: VerseIconInner.ResetIcon,
  [VerseIconIdEnum.CALENDAR]: VerseIconInner.CalendarIcon,
  [VerseIconIdEnum.TRASH]: VerseIconInner.TrashIcon,
  [VerseIconIdEnum.LIGHTNING]: VerseIconInner.LightningIcon,
  [VerseIconIdEnum.COMMAND]: VerseIconInner.CommandIcon,
  [VerseIconIdEnum.MIC_ON]: VerseIconInner.MicOnIcon,
  [VerseIconIdEnum.MIC_OFF]: VerseIconInner.MicOffIcon,
  [VerseIconIdEnum.SOUND_ON]: VerseIconInner.SoundOnIcon,
  [VerseIconIdEnum.SOUND_OFF]: VerseIconInner.SoundOffIcon,
  [VerseIconIdEnum.VIDEO_ON]: VerseIconInner.VideoOnIcon,
  [VerseIconIdEnum.VIDEO_OFF]: VerseIconInner.VideoOffIcon,
  [VerseIconIdEnum.HEART]: VerseIconInner.HeartIcon,
  [VerseIconIdEnum.CHECK_V1]: VerseIconInner.CheckV1Icon,
  [VerseIconIdEnum.CHECK_V2]: VerseIconInner.CheckV2Icon,
  [VerseIconIdEnum.PASTE]: VerseIconInner.PasteIcon,
  [VerseIconIdEnum.PENCIL]: VerseIconInner.PencilIcon,
  [VerseIconIdEnum.PIN]: VerseIconInner.PinIcon,
  [VerseIconIdEnum.PIN_OFF]: VerseIconInner.PinOffIcon,
  [VerseIconIdEnum.PLACEHOLDER]: VerseIconInner.PlaceholderIcon,
  [VerseIconIdEnum.PLAY_PREVIOUS]: VerseIconInner.PlayPreviousIcon,
  [VerseIconIdEnum.PLAY_NEXT]: VerseIconInner.PlayNextIcon,
  [VerseIconIdEnum.PLUS]: VerseIconInner.PlusIcon,
  [VerseIconIdEnum.POINTER]: VerseIconInner.PointerIcon,
  [VerseIconIdEnum.MINUS]: VerseIconInner.MinusIcon,
  [VerseIconIdEnum.BULB]: VerseIconInner.BulbIcon,
  [VerseIconIdEnum.CLIP]: VerseIconInner.ClipIcon,
  [VerseIconIdEnum.CLIPBOARD]: VerseIconInner.ClipboardIcon,
  [VerseIconIdEnum.OPEN]: VerseIconInner.OpenIcon,
  [VerseIconIdEnum.ANNOTATE]: VerseIconInner.AnnotateIcon,
  [VerseIconIdEnum.ARROW_DOWN_V1]: VerseIconInner.ArrowDownV1Icon,
  [VerseIconIdEnum.ARROW_DOWN_V2]: VerseIconInner.ArrowDownV2Icon,
  [VerseIconIdEnum.ARROW_LEFT_V1]: VerseIconInner.ArrowLeftV1Icon,
  [VerseIconIdEnum.ARROW_LEFT_V2]: VerseIconInner.ArrowLeftV2Icon,
  [VerseIconIdEnum.ARROW_RIGHT_V1]: VerseIconInner.ArrowRightV1Icon,
  [VerseIconIdEnum.ARROW_RIGHT_V2]: VerseIconInner.ArrowRightV2Icon,
  [VerseIconIdEnum.ARROW_UP_V1]: VerseIconInner.ArrowUpV1Icon,
  [VerseIconIdEnum.ARROW_UP_V2]: VerseIconInner.ArrowUpV2Icon,
  [VerseIconIdEnum.BACK]: VerseIconInner.BackIcon,
  [VerseIconIdEnum.BAN]: VerseIconInner.BanIcon,
  [VerseIconIdEnum.MEGAPHONE]: VerseIconInner.MegaphoneIcon,
  [VerseIconIdEnum.BOX]: VerseIconInner.BoxIcon,
  [VerseIconIdEnum.CAMERA]: VerseIconInner.CameraIcon,
  [VerseIconIdEnum.EXPAND]: VerseIconInner.ExpandIcon,
  [VerseIconIdEnum.COLLAPSE]: VerseIconInner.CollapseIcon,
  [VerseIconIdEnum.CHEVRON_DOWN]: VerseIconInner.ChevronDownIcon,
  [VerseIconIdEnum.CHEVRON_LEFT]: VerseIconInner.ChevronLeftIcon,
  [VerseIconIdEnum.CHEVRON_RIGHT]: VerseIconInner.ChevronRightIcon,
  [VerseIconIdEnum.NAVIGATION_OPEN]: VerseIconInner.NavigationOpenIcon,
  [VerseIconIdEnum.NAVIGATION_CLOSE]: VerseIconInner.NavigationCloseIcon,
  [VerseIconIdEnum.CHEVRON_UP]: VerseIconInner.ChevronUpIcon,
  [VerseIconIdEnum.CLOSE]: VerseIconInner.CloseIcon,
  [VerseIconIdEnum.CSV]: VerseIconInner.CSVIcon,
  [VerseIconIdEnum.DESCRIPTION]: VerseIconInner.DescriptionIcon,
  [VerseIconIdEnum.DISTRIBUTE]: VerseIconInner.DistributeIcon,
  [VerseIconIdEnum.DOWNLOAD]: VerseIconInner.DownloadIcon,
  [VerseIconIdEnum.EMOJI_ADD_V1]: VerseIconInner.EmojiAddV1Icon,
  [VerseIconIdEnum.EMOJI_ADD_V2]: VerseIconInner.EmojiAddV2Icon,
  [VerseIconIdEnum.EMOJI_V1]: VerseIconInner.EmojiV1Icon,
  [VerseIconIdEnum.EMOJI_V2]: VerseIconInner.EmojiV2Icon,
  [VerseIconIdEnum.EYE]: VerseIconInner.EyeIcon,
  [VerseIconIdEnum.HIDE]: VerseIconInner.HideIcon,
  [VerseIconIdEnum.HIGHLIGHT_END]: VerseIconInner.HighlightEndIcon,
  [VerseIconIdEnum.HIGHLIGHT]: VerseIconInner.HighlightIcon,
  [VerseIconIdEnum.FULLSCREEN_V1]: VerseIconInner.FullscreenV1Icon,
  [VerseIconIdEnum.FULLSCREEN_V2]: VerseIconInner.FullscreenV2Icon,
  [VerseIconIdEnum.GIF]: VerseIconInner.GIFIcon,
  [VerseIconIdEnum.GRID]: VerseIconInner.GridIcon,
  [VerseIconIdEnum.HAND_HELP]: VerseIconInner.HandHelpIcon,
  [VerseIconIdEnum.HAND_UP]: VerseIconInner.HandUpIcon,
  [VerseIconIdEnum.HASHTAG]: VerseIconInner.HashtagIcon,
  [VerseIconIdEnum.HELP]: VerseIconInner.HelpIcon,
  [VerseIconIdEnum.INFO]: VerseIconInner.InfoIcon,
  [VerseIconIdEnum.LINK]: VerseIconInner.LinkIcon,
  [VerseIconIdEnum.LOADER]: VerseIconInner.LoaderIcon,
  [VerseIconIdEnum.LOCK]: VerseIconInner.LockIcon,
  [VerseIconIdEnum.MINIMIZE_V1]: VerseIconInner.MinimizeV1Icon,
  [VerseIconIdEnum.MINIMIZE_V2]: VerseIconInner.MinimizeV2Icon,
  [VerseIconIdEnum.MORE]: VerseIconInner.MoreIcon,
  [VerseIconIdEnum.MP3]: VerseIconInner.MP3Icon,
  [VerseIconIdEnum.MULTIPLE_CHOICE]: VerseIconInner.MultipleChoiceIcon,
  [VerseIconIdEnum.MUSIC]: VerseIconInner.MusicIcon,
  [VerseIconIdEnum.NEXT]: VerseIconInner.NextIcon,
  [VerseIconIdEnum.NOTIFICATION]: VerseIconInner.NotificationIcon,
  [VerseIconIdEnum.PAUSE_V1]: VerseIconInner.PauseV1Icon,
  [VerseIconIdEnum.PAUSE_V2]: VerseIconInner.PauseV2Icon,
  [VerseIconIdEnum.PDF]: VerseIconInner.PDFIcon,
  [VerseIconIdEnum.PLAY_V1]: VerseIconInner.PlayV1Icon,
  [VerseIconIdEnum.PLAY_V2]: VerseIconInner.PlayV2Icon,
  [VerseIconIdEnum.POLL]: VerseIconInner.PollIcon,
  [VerseIconIdEnum.POPOUT]: VerseIconInner.PopoutIcon,
  [VerseIconIdEnum.QNA]: VerseIconInner.QnAIcon,
  [VerseIconIdEnum.QUESTION]: VerseIconInner.QuestionIcon,
  [VerseIconIdEnum.RECORD_V1]: VerseIconInner.RecordV1Icon,
  [VerseIconIdEnum.RECORD_V2]: VerseIconInner.RecordV2Icon,
  [VerseIconIdEnum.RECORD_V3]: VerseIconInner.RecordV3Icon,
  [VerseIconIdEnum.REPLY_V1]: VerseIconInner.ReplyV1Icon,
  [VerseIconIdEnum.REPLY_V2]: VerseIconInner.ReplyV2Icon,
  [VerseIconIdEnum.SAVE]: VerseIconInner.SaveIcon,
  [VerseIconIdEnum.SEND]: VerseIconInner.SendIcon,
  [VerseIconIdEnum.SCREEN]: VerseIconInner.ScreenIcon,
  [VerseIconIdEnum.SEND]: VerseIconInner.SendIcon,
  [VerseIconIdEnum.SETTINGS]: VerseIconInner.SettingsIcon,
  [VerseIconIdEnum.SPEAKER]: VerseIconInner.SpeakerIcon,
  [VerseIconIdEnum.SPOTLIGHT]: VerseIconInner.SpotlightIcon,
  [VerseIconIdEnum.SPOTLIGHT_OFF]: VerseIconInner.SpotlightOffIcon,
  [VerseIconIdEnum.SPOTLIGHT_VIEW]: VerseIconInner.SpotlightViewIcon,
  [VerseIconIdEnum.PANEL_VIEW]: VerseIconInner.PanelViewIcon,
  [VerseIconIdEnum.STAGE_MIC]: VerseIconInner.StageMicIcon,
  [VerseIconIdEnum.STAR]: VerseIconInner.StarIcon,
  [VerseIconIdEnum.STAR_OFF]: VerseIconInner.StarOffIcon,
  [VerseIconIdEnum.STOP_V1]: VerseIconInner.StopV1Icon,
  [VerseIconIdEnum.STOP_V2]: VerseIconInner.StopV2Icon,
  [VerseIconIdEnum.TADAA]: VerseIconInner.TadaaIcon,
  [VerseIconIdEnum.TAPE]: VerseIconInner.TapeIcon,
  [VerseIconIdEnum.TEMPLATE]: VerseIconInner.TemplateIcon,
  [VerseIconIdEnum.THUMBS_UP]: VerseIconInner.ThumbsUpIcon,
  [VerseIconIdEnum.THUMBS_UP_V2]: VerseIconInner.ThumbsUpV2Icon,
  [VerseIconIdEnum.TIMER]: VerseIconInner.TimerIcon,
  [VerseIconIdEnum.TOOLBOX]: VerseIconInner.ToolboxIcon,
  [VerseIconIdEnum.UPDATE]: VerseIconInner.UpdateIcon,
  [VerseIconIdEnum.UPLOAD]: VerseIconInner.UploadIcon,
  [VerseIconIdEnum.USER]: VerseIconInner.UserIcon,
  [VerseIconIdEnum.USER_ADD]: VerseIconInner.UserAddIcon,
  [VerseIconIdEnum.USER_PAIR]: VerseIconInner.UserPairIcon,
  [VerseIconIdEnum.USER_GROUP]: VerseIconInner.UserGroupIcon,
  [VerseIconIdEnum.WARNING_V1]: VerseIconInner.WarningV1Icon,
  [VerseIconIdEnum.WARNING_V2]: VerseIconInner.WarningV2Icon,
  [VerseIconIdEnum.WIFI]: VerseIconInner.WifiIcon,
  [VerseIconIdEnum.WHITEBOARD]: VerseIconInner.WhiteboardIcon,
  [VerseIconIdEnum.ZOOM_IN]: VerseIconInner.ZoomInIcon,
  [VerseIconIdEnum.ZOOM_OUT]: VerseIconInner.ZoomOutIcon,
  [VerseIconIdEnum.BOLD]: VerseIconInner.BoldIcon,
  [VerseIconIdEnum.BULLETS]: VerseIconInner.BulletsIcon,
  [VerseIconIdEnum.H1]: VerseIconInner.H1Icon,
  [VerseIconIdEnum.H2]: VerseIconInner.H2Icon,
  [VerseIconIdEnum.ITALIC]: VerseIconInner.ItalicIcon,
  [VerseIconIdEnum.ORDERED_LIST]: VerseIconInner.OrderedListIcon,
  [VerseIconIdEnum.STRIKETHROUGH]: VerseIconInner.StrikethroughIcon,
  [VerseIconIdEnum.UNDERLINE]: VerseIconInner.UnderlineIcon,
  [VerseIconIdEnum.INDENT]: VerseIconInner.IndentIcon,
  [VerseIconIdEnum.OVAL_SMALL]: VerseIconInner.OvalSmallIcon,
  [VerseIconIdEnum.OVAL_BIG]: VerseIconInner.OvalBigIcon,
  [VerseIconIdEnum.BUTTER]: VerseIconInner.ButterIcon,
  [VerseIconIdEnum.TEMPLATE_V2]: VerseIconInner.TemplateIconV2,
  [VerseIconIdEnum.TOOL]: VerseIconInner.ToolIcon,
  [VerseIconIdEnum.BLOCK]: VerseIconInner.BlockIcon,
  [VerseIconIdEnum.ADD_TEMPLATE]: VerseIconInner.AddTemplateIcon,
  [VerseIconIdEnum.POLL_STANDARD]: VerseIconInner.PollStandardIcon,
  [VerseIconIdEnum.POLL_WORD_CLOUD]: VerseIconInner.PollWordCloudIcon,
  [VerseExternalIconIdEnum.FACEBOOK]: VerseIconInner.FacebookIcon,
  [VerseExternalIconIdEnum.INSTAGRAM]: VerseIconInner.InstagramIcon,
  [VerseExternalIconIdEnum.LINKEDIN]: VerseIconInner.LinkedInIcon,
  [VerseExternalIconIdEnum.TWITTER]: VerseIconInner.TwitterIcon,
  [VerseExternalIconIdEnum.PRODUCT_HUNT]: VerseIconInner.ProductHuntIcon,
  [VerseExternalIconIdEnum.FIGMA]: VerseIconInner.FigmaIcon,
  [VerseExternalIconIdEnum.GIPHY]: VerseIconInner.GiphyIcon,
  [VerseExternalIconIdEnum.GOOGLE]: VerseIconInner.GoogleIcon,
  [VerseExternalIconIdEnum.GOOGLE_CALENDAR]: VerseIconInner.GoogleCalendarIcon,
  [VerseExternalIconIdEnum.GOOGLE_CHROME]: VerseIconInner.GoogleChromeIcon,
  [VerseExternalIconIdEnum.GOOGLE_DOC_V1]: VerseIconInner.GoogleDocV1Icon,
  [VerseExternalIconIdEnum.GOOGLE_DOC_V2]: VerseIconInner.GoogleDocV2Icon,
  [VerseExternalIconIdEnum.GOOGLE_DRIVE]: VerseIconInner.GoogleDriveIcon,
  [VerseExternalIconIdEnum.GOOGLE_SHEETS]: VerseIconInner.GoogleSheetsIcon,
  [VerseExternalIconIdEnum.GOOGLE_SLIDES]: VerseIconInner.GoogleSlidesIcon,
  [VerseExternalIconIdEnum.MIRO]: VerseIconInner.MiroIcon,
  [VerseExternalIconIdEnum.MURAL]: VerseIconInner.MuralIcon,
  [VerseExternalIconIdEnum.NOTION]: VerseIconInner.NotionIcon,
  [VerseExternalIconIdEnum.UNSPLASH]: VerseIconInner.UnsplashIcon,
  [VerseExternalIconIdEnum.YOUTUBE]: VerseIconInner.YoutubeIcon,
  [VerseExternalIconIdEnum.SOUND_CLOUD]: VerseIconInner.SoundCloudIcon,

  [VerseEmojiIconIdEnum.BASIC_EMOJI]: VerseIconInner.BasicEmoji,
  [VerseEmojiIconIdEnum.SMILE_EMOJI]: VerseIconInner.SmileEmoji,
  [VerseEmojiIconIdEnum.WOOHOO_EMOJI]: VerseIconInner.WoohooEmoji,
  [VerseEmojiIconIdEnum.LOVE_THIS_EMOJI]: VerseIconInner.LoveThisEmoji,
  [VerseIllustrationIconIdEnum.HANDS]: VerseIconInner.HandsIllustration,
  [VerseIllustrationIconIdEnum.LOVE_THIS]: VerseIconInner.LoveThisIllustration,
};

export enum VerseIconClickableBGVariantEnum {
  SOLID = 'solid',
  SEMI_TRANSPARENT = 'semiTransparent',
}

export enum VerseIconClickablePaddingVariantEnum {
  AUTO = 'auto',
  NONE = 'none',
}
