import { VerseBody2, VerseSelect, VerseSelectSizeEnum } from "src/verse/src";

import { useForm } from "react-hook-form";
import { useVirtualBgContext } from "src/context/VirtualBgContext";
import { BackgroundTypeEnum } from "src/consts/background";
const BACKGROUND_TYPE_OPTIONS = [
  {
    label: "Gradient",
    value: BackgroundTypeEnum.GRADIENT,
  },
  {
    label: "Solid",
    value: BackgroundTypeEnum.SOLID,
  },
  {
    label: "Image",
    value: BackgroundTypeEnum.IMAGE,
  },
];

export function BackgroundTypeSelector() {
  const { backgroundType, backgroundTypeChange } = useVirtualBgContext();
  const { control } = useForm();

  return (
    <>
      <VerseSelect
        options={BACKGROUND_TYPE_OPTIONS}
        onChange={(e) => {
          backgroundTypeChange?.(e.value as BackgroundTypeEnum);
        }}
        value={BACKGROUND_TYPE_OPTIONS.find((x) => x.value === backgroundType)}
        name="aspectRatio"
        testId="aspectRatio"
        control={control}
        width="100%"
        size={VerseSelectSizeEnum.MEDIUM}
      />
    </>
  );
}
