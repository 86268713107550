import {
  useVerseTheme,
  VerseBox,
  VerseButtonTextMedium,
  VerseCard,
  VerseH4,
} from "src/verse/src";
import styled from "styled-components";

import { useRef } from "react";
const StyledHex = styled.div<{ color: string }>`
  height: 28px;
  width: 28px;
  background-color: ${(props) => props.color};
  border-radius: 8px;
`;
const StyledInput = styled.input`
  visibility: hidden;
`;
export function HexSelectInput({
  color,
  colorChange,
}: {
  color: string;
  colorChange?: (color: string) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    inputRef?.current?.click();
  };
  const theme = useVerseTheme();
  return (
    <VerseCard
      height={40}
      cursor="pointer"
      p={0.75}
      onClick={handleSelectFile}
      borderRadius={12}
      borderColor={theme.colors.main.PrimaryDark20}
    >
      <VerseBox display="flex" alignItems="center" width="100%">
        <VerseBox mr={1.5}>
          <StyledHex color={color} />
        </VerseBox>

        <VerseButtonTextMedium>{color}</VerseButtonTextMedium>
        <StyledInput
          ref={inputRef}
          type="color"
          value={color}
          onChange={(e) => colorChange?.(e.target.value)}
        />
      </VerseBox>
    </VerseCard>
  );
}
