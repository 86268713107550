import {
  VerseBox,
  VerseButton,
  VerseButtonSizeEnum,
  VerseButtonVariantEnum,
  VerseIconIdEnum,
} from "src/verse/src";
import { useVirtualBgContext } from "src/context/VirtualBgContext";
import styled from "styled-components";
import { useRef } from "react";
import axios from "axios";
const StyledInput = styled.input`
  visibility: hidden;
`;
export function BackgroundImageSelector() {
  const inputRef = useRef<HTMLInputElement>(null);
  const { backgroundImageChange } = useVirtualBgContext();
  const handleSelectFile = () => {
    inputRef?.current?.click();
  };

  const handleRandomBg = () => {
    let randomURL = "https://source.unsplash.com/random";

    axios.get(randomURL).then((data) => {
      // the url of the random img
      backgroundImageChange?.(data.request.responseURL);
    });
  };
  return (
    <VerseBox width="100%" display="flex">
      <VerseButton
        title="Random"
        testId="random-bg"
        icon={{
          iconId: VerseIconIdEnum.DISTRIBUTE,
        }}
        width="100%"
        size={VerseButtonSizeEnum.MEDIUM}
        variant={VerseButtonVariantEnum.SECONDARY_CTA}
        onClick={handleRandomBg}
        mr={1}
      />
      <VerseButton
        title="Upload"
        testId="upload-bg"
        width="100%"
        icon={{
          iconId: VerseIconIdEnum.UPLOAD,
        }}
        size={VerseButtonSizeEnum.MEDIUM}
        variant={VerseButtonVariantEnum.SECONDARY_CTA}
        onClick={handleSelectFile}
      />

      <StyledInput
        ref={inputRef}
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        onChange={(e) => {
          if (!e.target.files || e.target.files.length === 0) {
            return;
          }
          const objectUrl = URL.createObjectURL(e.target.files[0]);
          backgroundImageChange?.(objectUrl);
        }}
      />
    </VerseBox>
  );
}
