import styled from "styled-components";

import { useVirtualBgContext } from "src/context/VirtualBgContext";
import { HexSelectInput } from "src/components/HexSelectInput";
export function GradientHex2Input() {
  const { gradientHex2, gradientHex2Change } = useVirtualBgContext();

  return (
    <HexSelectInput color={gradientHex2} colorChange={gradientHex2Change} />
  );
}
