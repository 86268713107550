export enum VerseLabelVariantEnum {
  PRIMARY_DARK_10 = "primaryDark10",
  GREEN_50 = "green50",
  BLUE_25 = "blue25",
  PRIMARY_75 = "primary75",
  DANGER_25 = "danger25",
  BLUE_50 = "blue50",
  WHITE = "white",
}

export const LEGENDAIRY_LABEL_BG_URL =
  "https://files.butter.us/pricing/legendairyBG.jpeg";
