import { VerseThemeType, VerseThemeColorsType } from './typings';

const VERSE_COLORS: VerseThemeColorsType = {
  main: {
    PrimaryDark100: '#0A0B1E',
    PrimaryDark90: '#222334',
    PrimaryDark80: '#3B3C4B',
    PrimaryDark70: '#535461',
    PrimaryDark60: '#6C6D78',
    PrimaryDark50: '#84858E',
    PrimaryDark40: '#9D9DA5',
    PrimaryDark30: '#B5B6BB',
    PrimaryDark20: '#CECED2',
    PrimaryDark10: '#E6E7E8',
    PrimaryDark07: '#EEEEEF',
    PrimaryDark03: '#F8F8F8',
    PrimaryDark90RGBA: 'rgba(10, 11, 30, 0.9)',
    PrimaryDark80RGBA: 'rgba(10, 11, 30, 0.8)',
    PrimaryDark70RGBA: 'rgba(10, 11, 30, 0.7)',
    PrimaryDark60RGBA: 'rgba(10, 11, 30, 0.6)',
    PrimaryDark50RGBA: 'rgba(10, 11, 30, 0.5)',
    PrimaryDark40RGBA: 'rgba(10, 11, 30, 0.4)',
    PrimaryDark30RGBA: 'rgba(10, 11, 30, 0.3)',
    PrimaryDark20RGBA: 'rgba(10, 11, 30, 0.2)',
    PrimaryDark15RGBA: 'rgba(10, 11, 30, 0.15)',
    PrimaryDark13RGBA: 'rgba(10, 11, 30, 0.13)',
    PrimaryDark10RGBA: 'rgba(10, 11, 30, 0.1)',
    PrimaryDark07RGBA: 'rgba(10, 11, 30, 0.07)',
    PrimaryDark05RGBA: 'rgba(10, 11, 30, 0.05)',
    PrimaryDark03RGBA: 'rgba(10, 11, 30, 0.03)',
    PrimaryDark0RGBA: 'rgba(10, 11, 30, 0)',
    PrimaryYellow100: '#FFFD63',
    PrimaryYellow75: '#FFFE8A',
    PrimaryYellow50: '#FFFEB1',
    PrimaryYellow25: '#FFFED8',
    PrimaryYellow10: '#FFFFF0',
    PrimaryYellow75RGBA: 'rgba(255, 253, 99, 0.75)',
    PrimaryYellow50RGBA: 'rgba(255, 253, 99, 0.5)',
    PrimaryYellow25RGBA: 'rgba(255, 253, 99, 0.25)',
    PrimaryYellow10RGBA: 'rgba(255, 253, 99, 0.1)',
    PrimaryYellow0RGBA: 'rgba(255, 253, 99, 0)',
    PrimaryYellowButtonHover: '#F7F560',
  },
  other: {
    White: '#FFFFFF',
    White75RGBA: 'rgba(255, 255, 255, 0.75)',
    White40RGBA: 'rgba(255, 255, 255, 0.4)',
    White15RGBA: 'rgba(255, 255, 255, 0.15)',
    White0RGBA: 'rgba(255, 255, 255, 0)',
    Green100: '#00E8AB',
    Green75: '#40EEC0',
    Green50: '#80F4D5',
    Green25: '#BFF9EA',
    Green10: '#E5FDF7',
    Green75RGBA: 'rgba(0, 232, 171, 0.75)',
    Green50RGBA: 'rgba(0, 232, 171, 0.5)',
    Green25RGBA: 'rgba(0, 232, 171, 0.25)',
    Green10RGBA: 'rgba(0, 232, 171, 0.1)',
    Green0RGBA: 'rgba(0, 232, 171, 0)',
    Danger100: '#FD5656',
    Danger75: '#FE8080',
    Danger50: '#FEABAB',
    Danger25: '#FFD5D5',
    Danger10: '#FFEEEE',
    Danger75RGBA: 'rgba(253, 86, 86, 0.75)',
    Danger50RGBA: 'rgba(253, 86, 86, 0.5)',
    Danger25RGBA: 'rgba(253, 86, 86, 0.25)',
    Danger10RGBA: 'rgba(253, 86, 86, 0.1)',
    Danger0RGBA: 'rgba(253, 86, 86, 0)',
    Blue100: '#3AA5FC',
    Blue75: '#6BBBFD',
    Blue50: '#9DD2FE',
    Blue25: '#CEE8FE',
    Blue10: '#EBF6FF',
    Blue75RGBA: 'rgba(58, 165, 252, 0.75)',
    Blue50RGBA: 'rgba(58, 165, 252, 0.5)',
    Blue25RGBA: 'rgba(58, 165, 252, 0.25)',
    Blue10RGBA: 'rgba(58, 165, 252, 0.1)',
    Blue0RGBA: 'rgba(58, 165, 252, 0)',
    Sand100: '#4A4A23',
    Sand75: '#F2F2F0',
    Sand25: '#FAFAF9',
    Sand12: '#E9E9E5',
    Sand08: '#F1F1ED',
    Sand05: '#F6F6F4',
    Sand03: '#FAFAF8',
    Sand12RGBA: 'rgba(74, 74, 35, 0.12)',
    Sand08RGBA: 'rgba(74, 74, 35, 0.08)',
    Sand05RGBA: 'rgba(74, 74, 35, 0.05)',
    Sand03RGBA: 'rgba(74, 74, 35, 0.03)',
    Sand0RGBA: 'rgba(74, 74, 35, 0)',
    Peach10: '#FFF9F1',
    Peach100: '#FFDFB9',
    Warning: '#FFBF72',
    Link: '#0077D9',
    ToolboxBlue: '#E7F1FF',
    ToolboxGreen: '#DCFCE7',
    ToolboxYellow: '#FFF7DD',
    ToolboxRed: '#FFEDED',
    ToolboxPink: '#FFD9E7',
    AgendaSavingGreen: '#00AD80',
    MacOSDark: '#363936',
    Black: '#000000',
    Lilac: '#B0BDFF',
  },
  external: {
    miroYellow: '#FFD02F',
    figmaPurple: '#A259FF',
    gDriveGreen: '#0F9D58',
    muralPink: '#ff0063',
  },
};
const VERSE_Z_INDEX = {
  level: {
    base: 0,
    app: 10,
    appSecondary: 20,
    frame: 30,
    popup: 40,
    drawer: 50,
    dialog: 60,
    dialogPopup: 70,
    snackbar: 80,

    titlebar: 90,
    /**
     * @description only use this when necessary.
     * One use case is on Google's Picker dialog.
     */
    thirdPartyDialog: 1000,
  },
};
const VERSE_SHADOWS = {
  primaryDark07: `1px 1px 3px 0px ${VERSE_COLORS.main.PrimaryDark07RGBA};`,
  primaryDark10: `2px 2px 7px 0px ${VERSE_COLORS.main.PrimaryDark10RGBA};`,
  primaryDark13: `4px 4px 17px 0px ${VERSE_COLORS.main.PrimaryDark13RGBA};`,
  primaryDark100: `4px 4px 0px 0px ${VERSE_COLORS.main.PrimaryDark100};`,
  cardShadowWithBorder:
    '0px 0px 1px rgba(66, 71, 76, 0.25), 0px 4px 8px rgba(10, 11, 30, 0.06), 0px 8px 48px rgba(10, 11, 30, 0.08)',
  cardShadowNoBorder:
    '0px 4px 8px rgba(10, 11, 30, 0.06), 0px 8px 48px rgba(10, 11, 30, 0.08)',
  cardShadowHover:
    '0px 0px 1px rgba(66, 71, 76, 0.25), 0px 8px 16px rgba(10, 11, 30, 0.06), 0px 8px 48px rgba(10, 11, 30, 0.12)',
  cardShadowActive: '0px 0px 1px rgba(66, 71, 76, 1)',
};

export const VerseTheme: VerseThemeType = {
  colors: VERSE_COLORS,
  /**
   * Verse spacing
   * ---
   * @param multiplier must be a round number.
   * @returns 8 * `multiplier`.
   */
  spacing: (x: number) => {
    return x * 8;
  },
  zIndex: VERSE_Z_INDEX,
  shadows: VERSE_SHADOWS,
  animationSpeed: 150,
  helpers: {
    hover: {
      elevate: `
        transform: translate(-1px, -1px);
        box-shadow: 1px 1px 0 0 ${VERSE_COLORS.main.PrimaryDark100};
      `,
    },
    activeSpeakerHighlight: ({
      borderRadius,
      visible,
    }: {
      borderRadius: string;
      visible: boolean;
    }) => ` 
      ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        transition: border-width 0.15s linear;
          
        border-radius: ${borderRadius};
        border: 0px solid ${VERSE_COLORS.other.Blue100};
        border-width: ${visible ? `4px` : `0px`};
        z-index: ${VERSE_Z_INDEX.level.app + 2};
      }
    `,
    verseScrollbar: `
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        border: solid 1px transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: ${VERSE_COLORS.main.PrimaryDark20};
        border: solid 1px ${VERSE_COLORS.other.White};
        border-radius: 3px;
      }
    `,
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    xxxl: 1600,
    '1080p': 1920,
    '2k': 2400,
  },
};
