export const BUTTER_NOTES_ROOT = "/";
export const BUTTER_URL =
  "https://butter.us?utm_medium=website&utm_source=referral&utm_campaign=virtualbackground";
export const BUTTER_SIGN_UP_LINK =
  "https://app.butter.us/auth?auth=signup&utm_medium=website&utm_source=referral&utm_campaign=virtualbackground";
export const BUTTER_SIGN_IN_LINK =
  "https://app.butter.us/auth?utm_medium=website&utm_source=referral&utm_campaign=virtualbackground";
export const BUTTER_FEAUTRES_LINK =
  "https://www.butter.us/features?utm_medium=website&utm_source=referral&utm_campaign=virtualbackground";
export const BUTTER_DOWNLOAD_LINK =
  "https://app.butter.us/download?utm_medium=website&utm_source=referral&utm_campaign=virtualbackground";
export const BUTTER_TEMPLATES_LINK =
  "https://www.butter.us/templates?utm_medium=website&utm_source=referral&utm_campaign=virtualbackground";
export const BUTTER_PRICING_LINK =
  "https://www.butter.us/pricing?utm_medium=website&utm_source=referral&utm_campaign=virtualbackground";
export const BUTTER_BLOG_LINK =
  "https://www.butter.us/blog?utm_medium=website&utm_source=referral&utm_campaign=virtualbackground";
export const BUTTER_COMMUNITY_LINK =
  "https://www.butter.us/community?utm_medium=website&utm_source=referral&utm_campaign=virtualbackground";
