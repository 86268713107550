import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { VerseBody1, VerseIcon, VerseIconIdEnum } from '..';
import { useVerseTheme } from '../..';
import { VerseCheckboxSizeEnum, VerseCheckboxVariantEnum } from './consts';
import {
  CheckboxBaseComponentProps,
  VerseCheckboxComponentProps,
  VerseCheckboxVariant,
  VerseCheckboxWrapperProps,
  VerseCheckboxWrapperSharedProps,
} from './typings';

export const VerseCheckbox = (props: VerseCheckboxComponentProps) => {
  const { control, defaultValue = false, ...other } = props;

  if (control) {
    return <ControlledCheckbox {...props} />;
  }
  return (
    <CheckboxBase
      checked={defaultValue as boolean}
      readOnly={true}
      {...other}
    />
  );
};
export const ControlledCheckbox = ({
  onChange,
  name,
  control,
  defaultValue = false,
  rules,
  onFocus,
  ...other
}: VerseCheckboxComponentProps) => {
  const {
    field: { ref, value, onChange: defaultOnChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
    onFocus,
  });

  const combinedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    defaultOnChange(e.target.checked);
    onChange && onChange(e);
  };

  return (
    <CheckboxBase
      inputRef={ref}
      checked={value}
      name={name}
      onChange={combinedOnChange}
      inputProps={inputProps}
      {...other}
    />
  );
};

const CheckboxBase = ({
  size,
  label,
  onChange,
  readOnly,
  inputRef,
  checked = false,
  inputProps,
  name,
  id,
  testId,
  disabled,
  strokeColor,
  variant = VerseCheckboxVariantEnum.DARK,
  ...other
}: CheckboxBaseComponentProps) => {
  const theme = useVerseTheme();

  const strokeColorToUse = strokeColor ?? theme.colors.other.White;
  const readOnlyOnChange = () => {
    return false;
  };
  const defaultId = name ?? label?.split(' ').join('-');

  const iconSize = React.useMemo(() => {
    switch (size) {
      case VerseCheckboxSizeEnum.LARGE:
        return 14;
      case VerseCheckboxSizeEnum.MEDIUM:
      default:
        return 10;
    }
  }, [size]);

  return (
    <CheckboxWrapper
      {...other}
      size={size}
      data-testid={testId}
      disablePointerEvent={Boolean(readOnly || disabled)}
    >
      <HiddenInput
        id={id || `verse-checkbox-${defaultId}`}
        type="checkbox"
        disabled={disabled}
        onChange={readOnly ? readOnlyOnChange : onChange}
        checked={checked}
        readOnly={readOnly}
        variant={variant}
      />

      <Checkbox ref={inputRef} variant={variant} {...inputProps}>
        {checked && (
          <VerseIcon
            iconId={VerseIconIdEnum.CHECK_V1}
            size={iconSize}
            stroke={strokeColorToUse}
            strokePx={1.5}
          />
        )}
      </Checkbox>
      {label && (
        <StyledVerseBody1 ml={1} html component="span">
          {label}
        </StyledVerseBody1>
      )}
    </CheckboxWrapper>
  );
};

const Checkbox = styled.div<{
  variant?: VerseCheckboxVariant;
  background?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.other.White};
  border: 1px solid;
  border-color: ${({ theme, variant }) => {
    switch (variant) {
      case VerseCheckboxVariantEnum.BLUE:
        return theme.colors.other.Blue100;

      case VerseCheckboxVariantEnum.DARK:
      default:
        return theme.colors.main.PrimaryDark30;
    }
  }};

  cursor: pointer;
  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
`;

const HiddenInput = styled.input<{ variant?: VerseCheckboxVariant }>`
  display: none;

  :hover + ${Checkbox} {
    border-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue100;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark50;
      }
    }};
  }
  :disabled + ${Checkbox} {
    background-color: ${({ theme }) => theme.colors.other.Sand03};
    border-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow25;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue10;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark20;
      }
    }};
  }

  :checked + ${Checkbox} {
    background-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow100;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue100;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark100;
      }
    }};

    border-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow100;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue100;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark100;
      }
    }};
  }
  :checked:hover + ${Checkbox} {
    background-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow75;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue100;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark80;
      }
    }};

    border-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow75;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue100;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark80;
      }
    }};
  }
  :checked:disabled + ${Checkbox} {
    background-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow25;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue10;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark20;
      }
    }};

    border-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow25;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue10;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark20;
      }
    }};
  }

  :disabled + ${Checkbox} {
    pointer-events: none;
  }

  :active + ${Checkbox} {
    border-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow100;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue100;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark100;
      }
    }};
  }
  :active&:checked + ${Checkbox} {
    background-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow50;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue100;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark70;
      }
    }};

    border-color: ${({ theme, variant }) => {
      switch (variant) {
        case VerseCheckboxVariantEnum.YELLOW:
          return theme.colors.main.PrimaryYellow50;
        case VerseCheckboxVariantEnum.BLUE:
          return theme.colors.other.Blue100;

        case VerseCheckboxVariantEnum.DARK:
        default:
          return theme.colors.main.PrimaryDark70;
      }
    }};
  }

  ${({ readOnly }) => readOnly && `+ ${Checkbox}{pointer-events: none;}`}
`;

const CheckboxWrapper = styled.label<
  VerseCheckboxWrapperSharedProps & VerseCheckboxWrapperProps
>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  padding: 0;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  ${({ disablePointerEvent }) =>
    disablePointerEvent ? `pointer-events: none;` : ''}

  ${Checkbox} {
    ${({ size = VerseCheckboxSizeEnum.MEDIUM }) => {
      switch (size) {
        case VerseCheckboxSizeEnum.LARGE:
          return `
          height: 20px;
          width 20px;
          border-radius: 6px;
          `;
        case VerseCheckboxSizeEnum.MEDIUM:
        default:
          return `
          height: 16px;
          width 16px;
          border-radius: 4px;
          `;
      }
    }}
  }
`;

const StyledVerseBody1 = styled(VerseBody1)`
  && {
    flex: 1;
  }
`;
