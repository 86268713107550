import { useSnackbar, OptionsObject } from 'notistack';
import { VerseSnackbarMessageObj } from '../typings';

function useVerseSnackbar() {
  const { enqueueSnackbar, ...others } = useSnackbar();

  function enqueueVerseSnackbar(
    messageObject: VerseSnackbarMessageObj,
    options?: OptionsObject
  ) {
    enqueueSnackbar(messageObject as any, options);
  }

  return { enqueueSnackbar: enqueueVerseSnackbar, ...others };
}

export default useVerseSnackbar;
