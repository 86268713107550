import React from 'react';

import { VerseTypographyFontWeightEnum } from '../consts';
import {
  VerseTypographyComponentProps,
  VerseTypographyVariantProps,
} from '../typings';
import { WrappedVerseTypography } from '../WrappedVerseTypography';

export const H2 = (props: VerseTypographyComponentProps) => {
  const variantProps: VerseTypographyVariantProps = {
    fontSize: 32,
    lineHeight: 1.1,
    fontWeight: VerseTypographyFontWeightEnum.BOLD,
  };
  return <WrappedVerseTypography variantProps={variantProps} {...props} />;
};
