export const ThumbsUp = () => {
  return (
    <svg
      width="371"
      height="371"
      viewBox="0 0 371 371"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M123.425 299.39C127.336 300.367 163.516 340.458 240.764 321.88C252.498 316.991 258.365 300.368 263.254 277.877C268.143 255.387 266.384 154.649 238.809 149.782C205.563 143.916 203.607 128.27 202.629 116.536C201.651 104.802 207.518 73.5116 186.006 53.9549C168.796 38.3093 149.262 50.052 148.849 60.8007C147.871 86.2237 158.738 117.24 144.937 133.159C134.305 145.423 119.914 164.715 114.624 172.272C107.78 182.05 88.2233 177.161 81.3782 180.095C66.0995 186.643 58.8885 197.696 55.9547 238.765C53.0152 279.914 72.0888 294.501 79.4226 297.434C89.2008 301.346 119.513 298.412 123.425 299.39Z"
        fill="#FFFD63"
        stroke="#0A0B1E"
        stroke-width="9.275"
      />
      <path
        d="M296.956 150.991C268.066 148.066 228.341 150.991 208.117 154.063C177.782 158.67 190.782 194.763 208.117 194.763C227.739 194.763 264.053 194.758 299.845 188.62C322.235 184.78 312.89 152.604 296.956 150.991Z"
        fill="#FFFD63"
        stroke="#0A0B1E"
        stroke-width="9.275"
      />
      <path
        d="M295.018 194.016C269.289 191.091 233.91 194.016 215.899 197.087C188.882 201.695 200.46 237.788 215.899 237.788C233.374 237.788 265.715 237.783 297.592 231.644C317.532 227.805 308.526 195.552 295.018 194.016Z"
        fill="#FFFD63"
        stroke="#0A0B1E"
        stroke-width="9.275"
      />
      <path
        d="M293.134 237.039C271.081 234.114 240.756 237.039 225.318 240.111C202.161 244.718 212.085 280.811 225.318 280.811C240.297 280.811 268.017 280.806 295.34 274.668C312.432 270.828 304.712 238.575 293.134 237.039Z"
        fill="#FFFD63"
        stroke="#0A0B1E"
        stroke-width="9.275"
      />
      <path
        d="M292.89 280.064C274.756 277.138 249.821 280.063 237.126 283.135C215.35 288.404 221.939 323.836 237.126 323.836C249.443 323.836 272.237 323.831 294.704 317.692C308.758 313.853 303.835 281.829 292.89 280.064Z"
        fill="#FFFD63"
        stroke="#0A0B1E"
        stroke-width="9.275"
      />
      <path
        d="M117.551 168.361C113.64 172.924 104.448 190.264 106.795 211.385"
        stroke="#0A0B1E"
        stroke-width="9.275"
        stroke-linecap="round"
      />
    </svg>
  );
};
