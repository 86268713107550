import React from 'react';
import styled from 'styled-components';

import { PickerWrapper } from './EmojiMartStyledComponents';
import { VerseEmojiMartPickerProps } from './typings';

const { Picker } = require('emoji-mart-virtualized');
/**
 * VerseEmojiMartPicker
 * ---
 * @description Emoji picker extended from Material UI `Select` component,
 * injected with Verse UI styling.
 */
export const VerseEmojiMartPicker = ({
  handleEmojiSelect,
}: VerseEmojiMartPickerProps) => {
  return (
    <PickerWrapper>
      <Picker
        showPreview={false}
        showSkinTones={false}
        onSelect={handleEmojiSelect}
      />
    </PickerWrapper>
  );
};
