import React, { useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import styled from 'styled-components';
import { VerseBody1, VerseH4 } from '..';
import { useWindowResizeEnd } from '../../utils';
import { VerseButton } from '../VerseButton';
import {
  VerseButtonSizeEnum,
  VerseButtonVariantEnum,
} from '../VerseButton/consts';
import { VerseIcon } from '../VerseIcon';
import { VerseModalActionButtonShortcutKeyEnum } from './consts';
import {
  VerseInfoModalComponentProps,
  VerseModalContentContainerProps,
} from './typings';
import { VerseBaseModal } from './VerseBaseModal';

export const VerseInfoModal = ({
  title,
  content,
  primaryButton,
  secondaryButton,
  icon,
  testId,
  html,
  open,
  onClose,
  shouldCloseOnOverlayClick = false,
  ...other
}: VerseInfoModalComponentProps) => {
  const renderSecondaryButton = !secondaryButton?.hidden || onClose;
  const topContainerRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkIfOverflowing = () => {
    if (topContainerRef?.current) {
      const refInfo = topContainerRef.current;
      setIsOverflowing(refInfo.scrollHeight > refInfo.offsetHeight);
    }
  };

  useWindowResizeEnd({ cb: checkIfOverflowing, enabled: open });

  const preventPropagation = (e?: React.MouseEvent | React.KeyboardEvent) => {
    e?.stopPropagation();
  };

  return (
    <StyledVerseBaseModal
      open={open}
      onClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      {...other}
      testId={testId}
    >
      <VerseModalContentContainer onClick={preventPropagation}>
        <VerseModalTopContentContainer
          isOverflowing={isOverflowing}
          ref={topContainerRef}
        >
          {icon && <VerseIcon {...icon} mr={1.5} />}
          <VerseModalTextContainer>
            <VerseH4 mb={1.5} html={html} linkify={false}>
              {title}
            </VerseH4>

            {typeof content === 'string' ? (
              <VerseBody1 linkify={false} html={html}>
                {content}
              </VerseBody1>
            ) : (
              content
            )}
          </VerseModalTextContainer>
        </VerseModalTopContentContainer>

        <VerseModalButtonContainer>
          {renderSecondaryButton ? (
            <VerseButton
              testId={`confirmationDialog-secondaryButton`}
              variant={VerseButtonVariantEnum.SECONDARY_CTA}
              size={VerseButtonSizeEnum.MEDIUM}
              mr={0.5}
              title="Cancel"
              onClick={onClose}
              {...(secondaryButton ?? {})}
            />
          ) : null}
          <VerseButton
            testId={`confirmationDialog-primaryButton`}
            variant={VerseButtonVariantEnum.PRIMARY_CTA}
            size={VerseButtonSizeEnum.MEDIUM}
            shortcutKeyInfo={{
              targetKey: VerseModalActionButtonShortcutKeyEnum.PRIMARY,
              label: isMacOs ? 'return' : 'Enter',
              shouldListen: open,
              hidden: true,
            }}
            {...primaryButton}
          />
        </VerseModalButtonContainer>
      </VerseModalContentContainer>
    </StyledVerseBaseModal>
  );
};

const VerseModalContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledVerseBaseModal = styled(VerseBaseModal)`
  && {
    flex-direction: column;
    width: 480px;
    border-radius: 12px;
    box-shadow: ${({ theme }) => theme.shadows.primaryDark13};
    max-height: calc(100vh - 80px);
    overflow: hidden;
    padding: 0;
  }
`;
const VerseModalTopContentContainer = styled.div<VerseModalContentContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 36px 54px 8px 24px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border: solid 1px transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.main.PrimaryDark20};
    border: solid 1px white;
    border-radius: 3px;
  }

  ${({ isOverflowing, theme }) =>
    isOverflowing &&
    `border-bottom: 1px solid ${theme.colors.main.PrimaryDark20}`}
`;

const VerseModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const VerseModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px;
`;
