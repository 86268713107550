import { VerseBody2, VerseBox, VerseSubtitle3 } from "src/verse/src";
import { StyledRangeInput } from "src/components/FormElements/StyledRangeInput";
import { useVirtualBgContext } from "src/context/VirtualBgContext";
export function TextScaleInput() {
  const { textScale, textScaleChange } = useVirtualBgContext();
  return (
    <VerseBox>
      <VerseBody2 mb={1}>Scale</VerseBody2>
      <StyledRangeInput
        value={textScale}
        onChange={(evt) => textScaleChange?.(Number(evt.target.value))}
        min="0.1"
        step={0.1}
        max="10"
      />
    </VerseBox>
  );
}
