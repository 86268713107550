import debounce from 'lodash/debounce';
import React, { useContext } from 'react';
import { useController, Validate, ValidateResult } from 'react-hook-form';

import { VerseContext } from '../../../context';
import { useHover } from '../../../utils';
import { VerseInputDataValueEnum, VerseInputSizeEnum } from '../consts';
import {
  UseVerseInputControllerParams,
  VerseInputDataValues,
} from '../typings';

export const useVerseInputController = ({
  size = VerseInputSizeEnum.SMALL,
  onChange,
  type,
  disabled,
  readOnly,
  placeholder,
  name,
  control,
  defaultValue,
  rules,
  onFocus,
  onBlur,
  onPaste,
  onKeyDown,
  onKeyPress,
  max,
  min,
  dataPrivate,
  autoFocus,
  autoComplete,
  className,
  maxLength,
  inputRef,
  forceShowErrors,
  ...other
}: UseVerseInputControllerParams) => {
  const [dataValues, setDataValues] = React.useState<VerseInputDataValues[]>(
    defaultValue ? [VerseInputDataValueEnum.FILLED] : [],
  );
  const { theme } = useContext(VerseContext);
  function toggleDataValue({
    key,
    value,
  }: {
    key: VerseInputDataValues;
    value: boolean;
  }) {
    setDataValues(old => {
      if (value) return [...old, key];
      return old.filter(existingKey => existingKey !== key);
    });
  }
  const {
    field: {
      ref,
      value,
      onChange: defaultOnChange,
      onBlur: defaultOnBlur,
      ...inputProps
    },
    meta: { invalid },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
    onFocus,
  });

  /** add dataValue to apply different styling when input state is invalid */
  React.useEffect(() => {
    toggleDataValue({ key: VerseInputDataValueEnum.INVALID, value: invalid });
    invalid && checkErrors(value ?? '');
  }, [invalid]);

  const combinedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    defaultOnChange(inputValue);

    // attach checkErrors everytime value change when invalid
    invalid && checkErrors(inputValue);

    // setLocalChecked(e.target.checked);
    if (inputValue) {
      toggleDataValue({ key: VerseInputDataValueEnum.FILLED, value: true });
    } else {
      toggleDataValue({ key: VerseInputDataValueEnum.FILLED, value: false });
    }
    onChange && onChange(e);
  };
  const combinedOnBlur = (e?: React.FocusEvent<HTMLInputElement>) => {
    /**
     * so that it gives buffer for onClick to trigger
     */
    setTimeout(() => {
      toggleDataValue({ key: VerseInputDataValueEnum.FOCUS, value: false });
    }, 100);
    defaultOnBlur();
    onBlur && e && onBlur(e);
  };
  const combinedOnFocus = () => {
    toggleDataValue({ key: VerseInputDataValueEnum.FOCUS, value: true });
    onFocus && onFocus();
  };

  const inputIconSize = React.useMemo(() => {
    switch (size) {
      case VerseInputSizeEnum.LARGE:
        return 20;
      case VerseInputSizeEnum.MEDIUM:
      case VerseInputSizeEnum.SMALL:
      default:
        return 16;
    }
  }, [size]);

  const [validations, setValidations] = React.useState<
    { key: string; value: ValidateResult | Promise<ValidateResult> }[]
  >([]);

  const checkErrors = React.useCallback(
    debounce((input: string) => {
      const validationKeys = Object.keys(rules?.validate ?? {});
      const validationsObj: Record<
        string,
        ValidateResult | Promise<ValidateResult>
      > = {};
      if (rules?.required) {
        validationsObj.required = input.length > 0;
      }
      if (rules?.validate) {
        validationKeys?.forEach(key => {
          const validator = (rules?.validate as Record<string, Validate>)?.[
            key
          ];
          validationsObj[key] = validator(input);
        });
      }

      const newValidations = Object.keys(validationsObj).map(key => ({
        key,
        value: validationsObj[key],
      }));
      setValidations(newValidations);
    }, 500),
    [],
  );

  const [errorIconHovered, setErrorIconHovered] = React.useState(false);

  const previewTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );
  const handleOnHover = (value: boolean) => {
    if (value) {
      if (previewTimeout.current) {
        clearTimeout(previewTimeout.current);
      }
      setErrorIconHovered(true);
    }
  };
  const handleOnInputHover = (value: boolean) => {
    if (value) {
      if (previewTimeout.current) {
        clearTimeout(previewTimeout.current);
      }
    } else {
      previewTimeout.current = setTimeout(() => {
        setErrorIconHovered(false);
      }, theme.animationSpeed);
    }
  };
  const errorHoverEvents = useHover({ onHover: handleOnHover });
  const inputFieldHoverEvents = useHover({ onHover: handleOnInputHover });

  const isPasswordType = type === 'password';
  const [typeToUse, setTypeToUse] = React.useState(type);
  function togglePasswordVisibility() {
    isPasswordType &&
      setTypeToUse(old => (old === 'password' ? 'text' : 'password'));
  }

  /** add dataValue to apply different styling when input have value */
  React.useEffect(() => {
    if (
      value?.length > 0 &&
      !dataValues.includes(VerseInputDataValueEnum.FILLED)
    ) {
      toggleDataValue({ key: VerseInputDataValueEnum.FILLED, value: true });
    }
  }, [value]);

  return {
    /** className should be applied to outerWrapperProps so that we can customize both input and error stylying */
    outerWrapperProps: { className, ...other },
    inputWrapperProps: { dataValues, size, disabled, readOnly },
    styledInputProps: {
      ref: (e: any) => {
        inputRef = e;
        ref.current = e;
      },
      value,
      placeholder,
      onChange: combinedOnChange,
      onBlur: combinedOnBlur,
      onPaste: onPaste,
      onFocus: combinedOnFocus,
      type: typeToUse,
      disabled,
      readOnly,
      onKeyDown,
      onKeyPress,
      'data-private': dataPrivate,
      autoFocus,
      autoComplete,
      max,
      min,
      maxLength,
      className: className === 'mousetrap' ? className : undefined, // if className is mousetrap, use that, as it will trigger the mouseTrap API in the app
      ...inputProps,
    },
    inputIconSize,
    errorHoverEvents,
    inputFieldHoverEvents,
    showErrorIcon: dataValues.includes(VerseInputDataValueEnum.INVALID),
    showValidations:
      (forceShowErrors ||
        errorIconHovered ||
        dataValues.includes(VerseInputDataValueEnum.FOCUS)) &&
      invalid,
    validations,
    isPasswordType,
    isPasswordVisible: typeToUse !== 'password',
    togglePasswordVisibility,
  };
};
