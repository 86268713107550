import React, { cloneElement, ReactElement } from "react";
import { isMobile } from "react-device-detect";
import { createPortal } from "react-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import styled from "styled-components";
import { useVerseTheme } from "../..";
import { usePortal } from "../../utils";
import { VerseTooltipText } from "../VerseTypography/Internal/Tooltip/VerseTooltipText";
import { VerseTooltipVariantEnum } from "./consts/tooltipConsts";
import { VerseTooltipComponentProps } from "./typings";

const TOOLTIP_PORTAL_ID = "popper-tooltip-portal";
const REACT_PAPER_TOOLTIP_CONTAINER_CLASSNAME = "tooltip-container";
const REACT_PAPER_TOOLTIP_ARROW_CLASSNAME = "tooltip-arrow";

export const VerseTooltip = ({
  title,
  children,
  testId,
  enabled = true,
  placement,
  offset,
  ...other
}: VerseTooltipComponentProps) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement, offset });
  const portalRef = usePortal({ portalId: TOOLTIP_PORTAL_ID });
  const shouldShowTooltip = enabled && visible && !isMobile;
  const theme = useVerseTheme();
  return (
    <>
      {shouldShowTooltip
        ? createPortal(
            <TooltipContainer
              ref={setTooltipRef}
              {...getTooltipProps({
                className: REACT_PAPER_TOOLTIP_CONTAINER_CLASSNAME,
              })}
              {...other}
            >
              <div
                {...getArrowProps({
                  className: REACT_PAPER_TOOLTIP_ARROW_CLASSNAME,
                })}
                {...other}
              />
              <VerseTooltipText
                linkify={false}
                color={theme.colors.other.White}
                testId={testId}
              >
                {title}
              </VerseTooltipText>
            </TooltipContainer>,
            portalRef
          )
        : null}

      {React.Children.only(
        cloneElement(children as ReactElement<any>, {
          ref: (node: any) => {
            /** combine existing ref so children can be assigned ref from outside */
            setTriggerRef(node);
            const existingRef = (children as any)?.ref;
            if (existingRef) {
              existingRef.current = node;
            }
          },
        })
      )}
    </>
  );
};

const TooltipContainer = styled.div<any>`
  ${({ maxLine }) =>
    maxLine
      ? `
    display: -webkit-box;
    -webkit-line-clamp: ${maxLine};
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  `
      : ``}

  padding: 3px 6px;
  border-radius: 4px;

  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : ``)}
  ${({ variant, theme }) => {
    switch (variant) {
      case VerseTooltipVariantEnum.WARNING:
        return `
          --tooltipBackground: ${theme.colors.other.Danger100};
          --tooltipBorder: ${theme.colors.other.Danger100};
        `;
      case VerseTooltipVariantEnum.NEUTRAL:
      default:
        return `
          --tooltipBackground: ${theme.colors.main.PrimaryDark100};
          --tooltipBorder: ${theme.colors.main.PrimaryDark100};
        `;
    }
  }}
`;
